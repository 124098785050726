/* eslint-disable jsx-a11y/alt-text */
import { GridColDef } from "@mui/x-data-grid";
import Claim from "./Claim";
import reportingIcon from "../../assets/images/initate_reporting.png"
import { Tooltip } from "@material-tailwind/react";
import { Check, Close, Refresh } from "@mui/icons-material";
import InlineLoader from "src/components/loader/InlineLoader";
import { statusBorderColor } from "src/utils/constant";
import moment from "moment";

interface PropsData{
    handleClaimNumberClick:(claim: Claim,action?:any) => void
    handleIntiateReportingClick :(claim:Claim)=>void
    isLoading:boolean
    activeClaimId?:any
    t: (key: string) => string
}
  

const columnDefs = ({handleClaimNumberClick  ,handleIntiateReportingClick ,isLoading,activeClaimId,t}:PropsData): GridColDef<Claim>[] => [
    {
        field: "fileNumber",    
        headerName: t("Ampcus File Number"),
        width: 170,
        sortable: true,
        renderCell: (params) => (
            <div>
                <span className="cursor-pointer">{params.value}</span>
            </div>
        ),
    },
    {
        field: "claimNumber",
        headerName: t("Claim Number"),
        width: 160,
        sortable: true,
        renderCell: (params) => (
            <div onClick={() => handleClaimNumberClick(params.row)}>
                <span className="text-[#0b8ce8] underline cursor-pointer">{params.value}</span>
            </div>
        ),
    },
    { field: "clientName", headerName: t("Client Name"),  width: 150,sortable: true,
        renderCell: (params:any) => (
        <div>
            <span className="">{params.row?.clientDetails?.firstName} {params.row?.clientDetails?.lastName}</span>
        </div>
    ), },
    { field: "insuredBusinessName", headerName:t("Insured Business Name"), width: 180,sortable: true },
    {
        field: "assignee",
        headerName: t("Assigned Accountant"),
        width: 180,
        sortable: true
    },
    {
        field: "status",
        headerName:t("Status"),
        width: 300,
        sortable: true,
        renderCell: (params:any) => (
            // <span className={`status-text ${params.value === "Active" ? "text-[#008000]" : params.value === "Pending" ? "text-[#ffa500]" : "text-[#ff0000]"}`}>
            <span className={`${"font-medium !border-[1px] p-2 cursor-pointer rounded-md ui-"+[statusBorderColor[params?.row?.status]]}`}>    
            <Tooltip
                             content={params.row?.workItem?.WorkStatus||"-"}
                             animate={{
                               mount: { scale: 1, y: 0 },
                               unmount: { scale: 0, y: 25 },
                             }}
                             placement="top"
                           >
            {params.row?.workItem?.WorkStatus||"-"}
            </Tooltip>
            </span>
         
        ),
    },
  

    {
        field: "emailSent",
        headerName: t("Email Status"),
        // width: 150,
        sortable: false,
        renderCell: (params:any) => (
           
            <div className="flex ">
             
               {params.row?.emailSent?
               <span className="text-green-700">
               <Check className="text-green-700 center  mr-1"/> Success 
               </span>
               :
               (isLoading && activeClaimId===params.row.id)?
               <span className="mt-4 ml-10">
               <InlineLoader />
              </span>
               :
               <span className="text-red-700">
               <Close className="text-red-700 center  mr-1"/>  Failed  <Refresh  onClick={() => handleClaimNumberClick(params.row,"resendEmail")} className="mr-3 hover:cursor-pointer" />
               </span>
               }
            </div>
 
        ),
    },
    {
        field: "initiateReport",
        headerName: t("Initiate Report"),
        width: 150,
        sortable: false,
        renderCell: (params:any) => (
           
            <img src={reportingIcon} onClick={() => handleIntiateReportingClick(params.row)} width={"20px"} className="mt-3 cursor-pointer"/>
     
            // <span className={`status-text ${params.value === "Active" ? "text-[#008000]" : params.value === "Pending" ? "text-[#ffa500]" : "text-[#ff0000]"}`}>
        //    <CustomButton   style={{width:100}}
        //    className={cx(
        //         "bg-[#1F7FBB]",
        //         "border-[#1F7FBB]",
        //         "mt-3",

        //         "!w-auto",
        //           "h-[30px]"
        //       )}
        //       label="Intiate Reporting"
        //       onClick={() => onInitiatReporting(params.row)}
        //      />
        ),
    },
    {
        field: "dateTimeCreated",
        headerName: "Created Date",
        width: 170,
     sortable: false,
        renderCell: (params:any) => (
            // <span className={`status-text ${params.value === "Active" ? "text-[#008000]" : params.value === "Pending" ? "text-[#ffa500]" : "text-[#ff0000]"}`}>
            <span className="">    
            {params.value?moment(params.value).format("YYYY-MM-DD hh:mm A"):"-"}
            </span>
        ),
    },
    {
        field: "dateTimeUpdated",
        headerName: "Last Update Date",
        width: 170,
        sortable: true,
        renderCell: (params:any) => (
            // <span className={`status-text ${params.value === "Active" ? "text-[#008000]" : params.value === "Pending" ? "text-[#ffa500]" : "text-[#ff0000]"}`}>
            <span className="">    
            {params.value?moment(params.value).format("YYYY-MM-DD hh:mm A"):"-"}
            </span>
        ),
    },
   
];


export default columnDefs;
