import React, { useCallback, useState } from "react";
import { components, createFilter } from "react-select";
import { cx } from "class-variance-authority";
import Creatable from "react-select/creatable";
interface selectProps {
  options?: any;
  onChange?: (selectedOption: any) => void;
  onInputChange: (selectedOption: any) => void;
  value?: any;
  required?: boolean;
  onBlur?: (value: any) => void;
  onFocus?: (value: any) => void;
  label?: string;
  isShowCreate?: boolean;
}

const CustomOption = (props: any) => {
  const bgColor: React.CSSProperties = {
    backgroundColor: "#0774D9",
  };


  return (
    <components.Option {...props}>
      {props.data.phoneNumber ? (
        <div className="flex flex-row">
          <div
            className={cx(
              "font-poppins text-white text-xs",
              "w-10 h-10 hidden lg:block md:block  rounded-full border border-2 text-center "
            )}
            style={bgColor}
          >
            <span className="relative top-[28%]">
              {props.data.label[0].toUpperCase()}
            </span>
          </div>
          <div className="flex flex-col px-2">
            <span className="text-xs font-medium">{props.data.label}</span>
            <span className="font-poppins text-[10px] text-[#000]">
              {props.data.phoneNumber}
            </span>
          </div>
        </div>
      ) : (
        "No Option"
      )}
    </components.Option>
  );
};

const ComboBox = (props: selectProps) => {
  const [isTouched, setIsTouched] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleBlur = useCallback(
    (e: any) => {
      setIsTouched(true);
      if (!props.value.label) {
        setHasError(true);
      } else {
        setHasError(false);
      }

      if (props.onBlur) props.onBlur(e);
    },
    [props.value.label, props.onBlur]
  );

  const handleFocus = useCallback(
    (e: any) => {
      setIsTouched(true);
      if (props.onFocus) props.onFocus(e);
    },
    [props.onFocus]
  );

  const renderLabel = () => (
    <label
      className={`text-xs font-medium mb-1 ${hasError && isTouched && !props.value.label ? "text-red-500" : ""}`}
    >
      {props.label}
      {/* {hasError && isTouched && !props.value.label && (
        <span className="text-red-500 text-xs mt-1">{" is required"}</span>
      )} */}
      {props.required && <span className="text-red-500"> *</span>}
    </label>
  );

  return (
    <div className="flex flex-col">
      {props.label && renderLabel()}
      <Creatable
        isClearable
        options={props.options}
        value={props.value}
        className={`${hasError ? "border-2 rounded-lg border-red-500" : " text-blue-gray-700 text-sm"}`}
        components={{ Option: CustomOption }}
        onChange={props.onChange}
        onInputChange={props.onInputChange}
        isSearchable={true}
        getOptionLabel={(option) => option.label} // Ensure search works with label
        getOptionValue={(option) => option.value} // Ensure value is correct
        filterOption={createFilter({ ignoreAccents: false })}
        // allowCreateWhileLoading={false}
        formatCreateLabel={(inputValue) => `"${inputValue}"`} // It Allows to create new label
        onBlur={handleBlur}
        onFocus={handleFocus}
        isDisabled={props.isShowCreate}
      />
    </div>
  );
};

export default ComboBox;
