import { createSlice } from "@reduxjs/toolkit";
import axiosInstanceFileUpload from "src/api/axiosFileupload";
import axiosInstance from "src/api/axiosInstance";
import axiosInstanceChatbot from "src/api/axiosInstanceChatbot";
import axiosInstanceQues from "src/api/axiosInstanceQues";
import { addNewUser, changePassword, deleteUser, getUserById, getUserList, login, sendOtp, signup } from "src/api/user";

interface propState {
  loading: boolean;
  error: boolean;
  message: any;
  userData:any
  userDetails:any
  userList:any,
  deleteUserData:any
  otpRes?:any
}

export const getLocalUser=()=>{
const  data:any=  localStorage.getItem("UserInfo")
if(data){
const parsedData=JSON.parse(data);
// console.log(parsedData.token)
  axiosInstance.defaults.headers['Authorization'] = `Bearer ${parsedData?.token}`
  // axiosInstanceChatbot.defaults.headers['Authorization'] = `Bearer ${parsedData?.token}`
  // axiosInstanceFileUpload.defaults.headers['Authorization'] = `Bearer ${parsedData?.token}`
  // axiosInstanceQues.defaults.headers['Authorization'] = `Bearer ${parsedData?.token}`
  // console.log("data-----",parsedData.user)
  return parsedData
}

}
const initialState: propState = {
  loading: false,
  error: false,
  message: "",
  userData:getLocalUser(),
  userDetails:null,
  userList:null,
  deleteUserData:null,
  otpRes:null
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.message=""
      state.userData=null;
      state.otpRes=null;
  
    },
  },
  extraReducers: (builder) => {   
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.userData = null;
        state.message=""
        state.error=false
      })
      .addCase(login.fulfilled, (state, action:any) => {
        state.userData = action.payload;
        state.loading = false;
        state.message=action.payload?.message
        state.error=false
      })
      .addCase(login.rejected, (state, action:any) => {
        state.loading = false;
        state.userData = null;
        state.message=action.payload
        state.error=true
      })
      .addCase(signup.pending, (state) => {
        state.loading = true;
        // state.userData = null;
        state.message=""
        state.error=false
      })
      .addCase(signup.fulfilled, (state, action:any) => {
        // state.userData = action.payload?.data;
        state.loading = false;
        state.message=action.payload?.message
        state.error=false
      })
      .addCase(signup.rejected, (state, action:any) => {
        state.loading = false;
        // state.userData = null;
        state.message=action.payload
        state.error=true
      })
      .addCase(getUserById.pending, (state) => {
        state.loading = true;
        state.userDetails = null;
        state.message=""
        state.error=false
      })
      .addCase(getUserById.fulfilled, (state, action:any) => {
        state.userDetails = action.payload;
        state.loading = false;
        state.message=action.payload?.message
        state.error=false
      })
      .addCase(getUserById.rejected, (state, action:any) => {
        state.loading = false;
        state.userDetails = null;
        state.message=action.payload
        state.error=true
      })
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.message=""
        state.error=false
      })
      .addCase(changePassword.fulfilled, (state, action:any) => {
        state.loading = false;
        state.message=action.payload?.message
        state.error=false
      })
      .addCase(changePassword.rejected, (state, action:any) => {
        state.loading = false;
        state.message=action.payload
        state.error=true
      })

      .addCase(getUserList.pending, (state) => {
        state.loading = true;
        state.message="";
        state.userList=null;
        state.error=false
      })
      .addCase(getUserList.fulfilled, (state, action:any) => {
        state.loading = false;
        state.message=action.payload?.message
        state.userList=action.payload;
        state.error=false
      })
      .addCase(getUserList.rejected, (state, action:any) => {
        state.loading = false;
        state.message=action.payload
        state.userList=null;
        state.error=true
      })

      .addCase(addNewUser.pending, (state) => {
        state.loading = true;
        state.message="";
        // state.userList=null;
        state.error=false
      })
      .addCase(addNewUser.fulfilled, (state, action:any) => {
        state.loading = false;
        state.message=action.payload?.message
        // state.userList=action.payload;
        state.error=false
      })
      .addCase(addNewUser.rejected, (state, action:any) => {
        state.loading = false;
        state.message = action.payload
        state.error=true
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.deleteUserData = null;
        state.error = false
      })
      .addCase(deleteUser.fulfilled, (state, action:any) => {
        state.loading = false;
        state.deleteUserData = action.payload
        state.error = false
      })
      .addCase(deleteUser.rejected, (state, action:any) => {
        state.loading = false;
        state.deleteUserData = action.payload
        state.error = true
      })

      .addCase(sendOtp.pending, (state) => {
        state.loading = true;
        state.otpRes = null;
        state.error = false
      })
      .addCase(sendOtp.fulfilled, (state, action:any) => {
        state.loading = false;
        state.otpRes = action.payload
        state.error = false
      })
      .addCase(sendOtp.rejected, (state, action:any) => {
        state.loading = false;
        // state.otpRes = action.payload
        state.error = true
      })
  },
});
export const { logout } =
userSlice.actions;

export default userSlice.reducer;
