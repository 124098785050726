import { useDispatch, useSelector } from "react-redux";
import FormInput from "../../components/FormInput";
import { RootState } from "src/store";
import { setInsuredInfo } from "src/store/slices/claimSlice";
import { ClaimData } from "./ClaimData";
import { useCallback, useRef } from "react";
import { emailValidation, onlyAlphabet } from "src/utils";
import { Autocomplete } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { LANGUAGE } from "src/utils/constant";
import { setLanguageValue } from "src/store/slices/language";
import Select from "react-select";
import Flag from "react-world-flags";
import { useAppSelector } from "src/store/hooks";
import CountrySelect from "src/components/CountrySelect";
type InsuredInfo = Pick<
  ClaimData,
  | "insuredFirstName"
  | "insuredLastName"
  | "insuredTitle"
  | "insuredBusinessName"
  | "insuredPhoneNumber"
  | "insuredEmail"
  | "insuredPostalAddress"
  | "insuredLandmark"
  | "insuredCity"
  | "insuredState"
  | "insuredCountry"
  | "insuredPostalCode"
  | "placeId"
  | "language"
>;

const InsuredInformation: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const insuredInfo = useSelector(
    (state: RootState) => state.claim.insuredInfo
  );
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const claimInputs: {
    label: string;
    key: keyof InsuredInfo;
    required: boolean;
  }[] = [
    { label: t("Insured First Name"), key: "insuredFirstName", required: true },
    { label: t("Insured Last Name"), key: "insuredLastName", required: true },
    { label: t("Insured Title"), key: "insuredTitle", required: false },
    {
      label: t("Insured Business Name"),
      key: "insuredBusinessName",
      required: true,
    },
    {
      label: t("Insured Phone Number"),
      key: "insuredPhoneNumber",
      required: true,
    },
    { label: t("Insured Email Address"), key: "insuredEmail", required: true },
    {
      label: t("Insured Business Address"),
      key: "insuredPostalAddress",
      required: true,
    },
    // { label: "Landmark", key: "insuredLandmark", required: false },
    { label: t("City"), key: "insuredCity", required: true },
    { label: t("State"), key: "insuredState", required: true },
    { label: t("Country"), key: "insuredCountry", required: true },
    { label: t("Postal Code"), key: "insuredPostalCode", required: true },
  ];

  const handlePhoneNumber=(value:any)=>{
    dispatch(setInsuredInfo({ ...insuredInfo, insuredPhoneNumber: value }));
  }
  

  const handleInputChange = useCallback(
    (key: keyof InsuredInfo) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const reg = /^[0-9\b]+$/;
        if (key === "insuredPhoneNumber") {
          // if (reg.test(value) || value === "")
          //   dispatch(setInsuredInfo({ ...insuredInfo, [key]: value }));
        } else if (key === "insuredFirstName" || key === "insuredLastName") {
          if (onlyAlphabet(value)) {
            dispatch(setInsuredInfo({ ...insuredInfo, [key]: value }));
          }
        } else {
          dispatch(setInsuredInfo({ ...insuredInfo, [key]: value }));
        }

        if (key === "insuredPostalAddress" && value === "") {
          dispatch(
            setInsuredInfo({
              ...insuredInfo,
              insuredPostalAddress: "",
              insuredLandmark: "",
              insuredCity: "",
              insuredState: "",
              insuredCountry: "",
              insuredPostalCode: "",
              placeId: "",
            })
          );
        }
        if (
          key === "insuredPostalAddress" &&
          insuredInfo?.insuredPostalCode !== ""
        ) {
          dispatch(
            setInsuredInfo({
              ...insuredInfo,
              insuredPostalAddress: "",
              insuredLandmark: "",
              insuredCity: "",
              insuredState: "",
              insuredCountry: "",
              insuredPostalCode: "",
              placeId: "",
            })
          );
        }

        // dispatch(setInsuredInfo({ ...insuredInfo, [key]: value }));
      },
    [dispatch, insuredInfo]
  );
  const handlePlaceSelect = () => {
    const place = autocompleteRef.current?.getPlace();
    if (!place || !place.address_components) return;

    const addressComponents = place.address_components;

    const getComponent = (type: string) =>
      addressComponents.find((component) => component.types.includes(type))
        ?.long_name || "";

    const postalAddress = place.formatted_address || "";
    const landmark =
      getComponent("sublocality") || getComponent("neighborhood") || "";
    const city = getComponent("locality") || "";
    const state = getComponent("administrative_area_level_1") || "";
    const country = getComponent("country") || "";
    const pincode = getComponent("postal_code") || "";
    const placeId = place.place_id || "";

    dispatch(
      setInsuredInfo({
        ...insuredInfo,
        insuredPostalAddress: postalAddress,
        insuredLandmark: landmark,
        insuredCity: city,
        insuredState: state,
        insuredCountry: country,
        insuredPostalCode: pincode,
        placeId: placeId,
      })
    );
  };
  const customOptionLabel = (option: any) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Flag
        code={option.flag}
        style={{ width: 20, height: 15, marginRight: 8 }}
      />
      {option.name}
    </div>
  );
  const onChangeLang = (selectedOption: any) => {
    const selectedLanguage = selectedOption.value;

    dispatch(
      setInsuredInfo({
        ...insuredInfo,
        language: selectedLanguage,
      })
    );
  };

  return (
    <>
      <div className="mt-1">
        <span className="font-bold text-[#045373]">
          {t("Insured Information")}
        </span>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3  gap-2">
        {claimInputs.map(({ label, key, required }) => (
          <div key={label} className="flex flex-col ">
            {key === "insuredPostalAddress" ? (
              <Autocomplete
                onLoad={(ref) => (autocompleteRef.current = ref)}
                onPlaceChanged={handlePlaceSelect}
              >
                <FormInput
                  name="insuredPostalAddress"
                  label={t("Insured Business Address")}
                  required={true}
                  type="text"
                  className="mt-1 h-[34px] border-2 border-[#CECECE] border-t-[#CECECE] bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10 focus:border-transparent"
                  value={insuredInfo?.insuredPostalAddress || ""}
                  onChange={handleInputChange("insuredPostalAddress")}
                />
              </Autocomplete>
            ) : key === "insuredPhoneNumber" ? (
         
                <CountrySelect
                  name={label}
                  label={label}
                  required={required}
                  value={insuredInfo?.insuredPhoneNumber || ""}
                  // className="border-2 border-[#CECECE]"
                  
                  onChange={handlePhoneNumber}
                />
       
            ) : (
              <FormInput
                name={label}
                label={label}
                required={required}
                type="text"
                className="mt-1 h-[34px] border-2 border-[#CECECE] border-t-[#CECECE] bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10 focus:border-transparent"
                value={insuredInfo?.[key] || ""}
                onChange={handleInputChange(key)}
              />
            )}
            {key == "insuredEmail" &&
              !emailValidation(insuredInfo?.["insuredEmail"]) && (
                <span className="text-red-700 text-xs">
                  {" "}
                  Enter valid email{" "}
                </span>
              )}
          </div>
        ))}
        <div className="flex flex-col ">
          <label className="text-xs font-medium mb-1 ">
            {t("Preferred Language")}
          </label>
          <Select
            placeholder={t("Select Language")}
            className="p-0 pr-[18px] text-sm text-blue-gray-700 w-full h-[38px] rounded-md bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 focus:border-1 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10"
            id="language"
            value={{
              value: insuredInfo.language,
              name: LANGUAGE.find(
                (option: any) => option.value === insuredInfo.language
              )?.name,
              flag: LANGUAGE.find(
                (option: any) => option.value === insuredInfo.language
              )?.flag,
            }}
            options={LANGUAGE}
            onChange={onChangeLang}
            formatOptionLabel={customOptionLabel}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: "white", // Set the background color htmlFor the control
              }),
              option: (provided) => ({
                ...provided,
                color: "black",
                backgroundColor: "transparent", // Set the background color htmlFor the options
              }),
            }}
          />
        </div>
      </div>
    </>
  );
};

export default InsuredInformation;
