import axiosInstance from './axiosInstance';
import { createAsyncThunk } from '@reduxjs/toolkit';
export const updateUserLanguage = createAsyncThunk("updateUserLanguage", async (payload:any, { rejectWithValue }) => {
    try {
      const claimId=payload?.claimId
      const response = await axiosInstance.put('/claim/updateUserLanguage/'+claimId,{language:payload.language});
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  });