/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridPaginationModel } from "@mui/x-data-grid";
import TuneIcon from "@mui/icons-material/Tune";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import {
  resetClaims,
  updateAssignedAccountant,
  updateBusinessNumber,
  updateClaimNumber,
  updateClientName,
  updateFileNumber,
} from "../../store/slices/dashboardSlice";
import Filters from "./Filters";
import columnDefs from "./ColumnDefs";
import FormInput from "../../components/FormInput";
import CustomButton from "../../components/CustomButton";
import "./Dashboard.css";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getClaimList, PaginationSearchPayloadProps, resendEmail, updateClaimDataStatus } from "src/api/claimApi";
import Loader from "src/components/loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {   } from "src/store/slices/claimSlice";
import { useTranslation } from 'react-i18next';
import Claim from "./Claim";
import HideShowColoum from "./HideShowColoum";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const claimState = useAppSelector((state: RootState) => state.claim);
  const [searchKey, setSearchkey] = useState<string>("")
  const[selectedClaimId,setSelectedClaimId]=useState<any>("")
  const { filters } = useSelector(
    (state: RootState) => state.dashboardClaims
  );
  const {
    claimNumber,
    ampcusFileNumber,
    insuredBusinessName,
    assignedAccountant,
    clientName,
  } = filters;

  const [sortModel, setSortModel] = useState<any>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { t } = useTranslation();
    const selectedValues = useAppSelector((state: RootState) => state.dashboardColumn.selectedValues);
  useEffect(() => {
    const data: PaginationSearchPayloadProps = {
      pageNo: currentPage,
      pageSize: rowsPerPage,
      data: "",
      orderBy: sortModel[0]?.sort.toUpperCase() || "DESC"
    };
    if (searchKey === "") {
      dispatch(getClaimList(data));
    }
  }, [dispatch, searchKey, currentPage, rowsPerPage]);

  useEffect(()=>{
if(claimState.error==""&& claimState?.resendMessage){
  toast.success(claimState?.resendMessage)
  setSelectedClaimId("")
  const payload={claimId:selectedClaimId,data:claimState.data}
  dispatch(updateClaimDataStatus(payload))

}else if(claimState.error && claimState?.resendMessage){
  toast.error(claimState?.resendMessage)
}
  },[claimState?.resendMessage])

  const _onSearch = () => {
    const data: PaginationSearchPayloadProps = {
      pageNo: currentPage,
      pageSize: rowsPerPage,
      data: searchKey,
      orderBy: sortModel[0]?.sort.toUpperCase() || "DESC"
    };
    dispatch(getClaimList(data));
  }

  const fetchSortedData = async (sortModel:any) => {
    const lSortBy=sortModel[0]?.field
    
    const payload = {
      "data": "",
      pageNo: currentPage,
      pageSize: rowsPerPage,
      "orderBy": sortModel[0]?.sort.toUpperCase() || "DESC",
      "sortBy": lSortBy==="clientName"?"clientDetailsFirstName": lSortBy,
      "searchClaimQuery": {
        "fileNumber": ampcusFileNumber,
        "claimNumber": claimNumber,
        "assignee": assignedAccountant,
        "insuredBusinessName": insuredBusinessName,
        "clientName": clientName
      }
    }
    dispatch(getClaimList(payload));
  };


  const handleSearch = useCallback(() => {
    const payload = {
      "data": "",
      pageNo: currentPage,
      pageSize: rowsPerPage,
      "orderBy":"",
      "sortBy":"",
      "searchClaimQuery": {
        "fileNumber": ampcusFileNumber,
        "claimNumber": claimNumber,
        "assignee": assignedAccountant,
        "insuredBusinessName": insuredBusinessName,
        "clientName": clientName
      }

    }
    dispatch(getClaimList(payload));
  }, [ampcusFileNumber, claimNumber, assignedAccountant, insuredBusinessName, clientName, dispatch]);

  const handleReset = () => {
    dispatch(resetClaims());
    _onSearch()
  };

  const handlePaginationChange = (paginationModel: GridPaginationModel) => {
    setRowsPerPage(paginationModel.pageSize);
    setCurrentPage(paginationModel.page);
    const payload = {
      pageNo: paginationModel.page,
      pageSize: paginationModel.pageSize,
      data: searchKey,
      orderBy: sortModel.length ? sortModel[0]?.sort?.toUpperCase() : 'DESC',
      sortBy: sortModel.length ? sortModel[0]?.field : "",
      searchClaimQuery: {
        fileNumber: ampcusFileNumber,
        claimNumber: claimNumber,
        assignee: assignedAccountant,
        insuredBusinessName: insuredBusinessName,
        clientName: clientName,
      },
    };
    dispatch(getClaimList(payload)); 
  };

  const handleClaimNumberClick = (claim: any,action?:any) => {
    if(action==="resendEmail"){
      setSelectedClaimId(claim?.id)
      dispatch(resendEmail(claim?.id))
      return
    }
    if (!claim?.clientDetails) {
      toast.error("There is no client found against this claim.")
    } else {
      navigate(`/claim-details`, { state: { claim } });
    }
  };
  const handleIntiateReportingClick = (claim: any) => {
    if (!claim?.clientDetails) {
      toast.error("There is no client found against this claim.")
    } else {
      navigate(`/initiate-reporting`, { state: { claim } });
    }
  };

  useEffect(() => {
    // Initial fetch on component mount or whenever sortModel changes
    fetchSortedData(sortModel);
  }, [sortModel]);
  const [columnVisibility, setColumnVisibility] = useState<GridColumnVisibilityModel>({
    fileNumber: selectedValues.includes("fileNumber")?true:false,
    claimNumber: selectedValues.includes("claimNumber")?true:false,
    clientName: selectedValues.includes("clientName")?true:false,
    insuredBusinessName: selectedValues.includes("insuredBusinessName")?true:false,
    status: selectedValues.includes("status")?true:false,
    assignee:selectedValues.includes("assignee")?true:false,
    emailSent:selectedValues.includes("emailSent")?true:false,
    initiateReport:selectedValues.includes("initiateReport")?true:false,
    dateTimeCreated:selectedValues.includes("dateTimeCreated")?true:false,
    dateTimeUpdated:selectedValues.includes("dateTimeUpdated")?true:false
  });

  const updateColumnVisibility = (key: string,value:boolean) => {
    setColumnVisibility((prevVisibility) => ({
      ...prevVisibility,
      [key]: value,
    }));
  }

  return (
    <div>
      <div className="mb-5">
        <div
          className={`fixed top-0 right-0 h-full w-64 bg-white text-black z-[9999999] transform ${isOpen ? "translate-x-0 box-sha" : "translate-x-full"
            } transition-transform duration-300`}
        >

          <Filters
            setIsOpen={setIsOpen}
            claimNumber={claimNumber}
            ampcusFileNumber={ampcusFileNumber}
            insuredBusinessName={insuredBusinessName}
            assignedAccountant={assignedAccountant}
            clientName={clientName}
            setClaimNumber={(value) => dispatch(updateClaimNumber(value))}
            setFileNumber={(value) => dispatch(updateFileNumber(value))}
            setBusinessNumber={(value) =>
              dispatch(updateBusinessNumber(value))
            }
            setAssignedAccountant={(value) =>
              dispatch(updateAssignedAccountant(value))
            }
            setClientName={(value) => dispatch(updateClientName(value))}
            onSearch={handleSearch}
            onReset={handleReset}
            t={t}
          />
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col md:flex-row mb-5 gap-3 md:gap-3">
          <FormInput
            name="Search"
            placeholder={t("Search")}
            type="text"
            className="w-full md:w-96 bg-white text-black-900 focus:border-2 focus:borer-t-[#212121] focus:shadow-lg focus:shadow-sky-100 placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900"
            value={searchKey}
            onChange={(e) => setSearchkey(e.target.value)}
          />
          <CustomButton
            className="w-full md:w-auto get__started__btn rounded-lg pl-6 pr-6 font-semibold"
            label={t("Search")}
            onClick={_onSearch}
          />
        </div>
        <div className="flex flex-col md:flex-row md:justify-between items-center gap-3 md:gap-3">
        <HideShowColoum updateColumnVisibilityFun={updateColumnVisibility}/>
        <button
          className="bg-[#eaeaea] p-2 rounded-md -mt-4 w-full"
          onClick={() => setIsOpen(!isOpen)}
        >
          <TuneIcon className="mr-2" />
          {t("Filters")}
        </button>
        </div>
      </div>
      <div className="userselect">
        <Box sx={{ height: 350, minHeight: 450, width: "100%" }}>
          {claimState?.loading ? (
            <Loader />
          ) : (
            <DataGrid
              rows={claimState?.data?.data}
              columns={columnDefs({handleClaimNumberClick,handleIntiateReportingClick,isLoading:claimState.inlineLoader,activeClaimId:selectedClaimId,t})}
              paginationModel={{
                page: currentPage,
                pageSize: rowsPerPage,
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              rowCount={claimState?.data?.totalRecords || 0}
              paginationMode="server"
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={(model:any) => {
                setSortModel(model);
                // if(sortModel.length>0){
                //   setSortModel(model)
                // }
                // else if(sortModel.length>0){
                //    const sortDataArray=[...sortModel]
                //    const sortData:any=sortDataArray[0]
                //    sortData.sort=sortData.sort==="desc"?"asc":"desc"
                //   setSortModel([sortData])
                // }else{
                //   setSortModel(model)
                // }

              
              }}
              onPaginationModelChange={handlePaginationChange}
              columnVisibilityModel={columnVisibility}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#cfdce4",
                },
                "& .MuiDataGrid-root": {
                  overflowX: "auto",
                },
              }}
            //   checkboxSelection
            />
          )}
        </Box>
      </div>
    </div>
  );
};

export default Dashboard;
