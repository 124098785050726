import { Box } from "@mui/material";
import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import columnDefs from "./ColumnDefs";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { addNewUser, getUserList, deleteUser } from "src/api/user";
import { RootState } from "src/store";
import Button from "src/components/atoms/button";
import { DialogCustomAnimation } from "src/components/dialog";
import FormInput from "src/components/FormInput";
import { toast } from "react-toastify";
import Loader from "src/components/loader";

// interface UserProps {
//   id: number;
//   email: string;
//   firstName: string;
//   lastName: string;
//   mobile: string;
//   createdDate: string;
//   roles: any;
// }

export default function UserList() {
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const { userList,loading } = useAppSelector((state: RootState) => state.user);
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
  });
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ id: "",firstName:"",lastName:"" });
  const handlePaginationChange = (paginationModel: GridPaginationModel) => {
    setRowsPerPage(paginationModel.pageSize);
    setCurrentPage(paginationModel.page);
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getUserList(""));
  }, []);

  useEffect(() => {
    setUserInfo({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
    });
  }, [open]);

  const handleSave = () => {
    const payload = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      mobile: userInfo.mobile,
      email: userInfo.email,
    //   password: userInfo.password,
      roles: ["ROLE_USER"],
    };
    if (
      userInfo.firstName &&
      userInfo.lastName &&
      userInfo.mobile &&
      userInfo.email 
    //   userInfo.password
    ) {
      dispatch(addNewUser(payload)).then((res: any) => {
        if(res?.payload?.message){
          toast.success(res?.payload?.message);
          setOpen(false);
          dispatch(getUserList(""));
        }else{
          toast.success(res?.payload);
        }
        
      });
    }
  };
  const deleteUserFunc = (row: any) => {
    setSelectedUser(row);
    setDeleteOpen(true);
  };

  const yesToDelete = () => {
    let row = selectedUser?.id;
    dispatch(deleteUser(row)).then((res: any) => {
      setDeleteOpen(false)
      toast.success(res?.payload?.message);
      dispatch(getUserList(""));
    });
  };
  return (
    <div>
       { loading &&<Loader />}
      <div className="text-right">
        <Button
          onClick={() => {
            setOpen(true);
          }}
        >
          Add User
        </Button>
      </div>
      <br />
      <Box
        sx={{
          height: 350,
          minHeight: 450,
          width: "100%",
          backgroundColor: "#FFF",
        }}
      >
        <DataGrid
          rows={userList?.data}
          columns={columnDefs({ deleteUserFunc, t })}
          paginationModel={{
            page: currentPage,
            pageSize: rowsPerPage,
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          rowCount={userList?.data?.length || 0}
          paginationMode="server"
          sortingMode="server"
          //   sortModel={sortModel}
          //   onSortModelChange={(model:any) => {
          //     setSortModel(model);
          //     // if(sortModel.length>0){
          //     //   setSortModel(model)
          //     // }
          //     // else if(sortModel.length>0){
          //     //    const sortDataArray=[...sortModel]
          //     //    const sortData:any=sortDataArray[0]
          //     //    sortData.sort=sortData.sort==="desc"?"asc":"desc"
          //     //   setSortModel([sortData])
          //     // }else{
          //     //   setSortModel(model)
          //     // }

          //   }}
          onPaginationModelChange={handlePaginationChange}
          //   columnVisibilityModel={columnVisibility}
          sx={{
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "#cfdce4",
            },
            "& .MuiDataGrid-root": {
              overflowX: "auto",
              backgroundColor: "#FFF",
            },
            "& .MuiDataGrid-row": {
              backgroundColor: "#FFF", // Row background color
            },
            "& .MuiDataGrid-cell": {
              backgroundColor: "#FFF", // Cell background color
            },
          }}
        />
      </Box>
      <DialogCustomAnimation
        open={open}
        handleSave={handleSave}
        handleOpen={() => setOpen(false)}
        title="Select Interview Mode"
        body={
          <div>
            <h3>Fill User Details</h3>
            <br />

            <FormInput
              value={userInfo.firstName}
              label={"First Name"}
              onChange={(e) =>
                setUserInfo({ ...userInfo, firstName: e.target.value })
              }
            />
            <br />
            <FormInput
              value={userInfo.lastName}
              label={"Last Name"}
              onChange={(e) =>
                setUserInfo({ ...userInfo, lastName: e.target.value })
              }
            />
            <br />
            <FormInput
              value={userInfo.email}
              label={"Email"}
              onChange={(e) =>
                setUserInfo({ ...userInfo, email: e.target.value })
              }
            />
            <br />
            <FormInput
              value={userInfo.mobile}
              label={"Mobile"}
              maxLength={10}
              onChange={(e) =>{
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                   setUserInfo({ ...userInfo, mobile: e.target.value })
                }
              }
              
              }
            />
            {/* <br /> */}
            {/* <FormInput
              value={userInfo.password}
              type="password"
              label={"Password"}
              onChange={(e) =>
                setUserInfo({ ...userInfo, password: e.target.value })
              }
            /> */}
          </div>
        }
      />

      <DialogCustomAnimation
        open={deleteOpen}
        handleDelete={yesToDelete}
        handleOpen={() => setDeleteOpen(false)}
        title=""
        body={
          <div className="flex justify-center">
            <h3 className="font-bold text-xl text-black">Are your sure to delete user ({selectedUser.firstName+" "+selectedUser.lastName}) ? </h3>
            <br />
          </div>
        }
      />

      {/* <DialogCustomAnimation open={true} handleOpen={}></DialogCustomAnimation> */}
    </div>
  );
}
