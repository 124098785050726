/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import botImg from "../../assets/images/chat.png";
import handIcon from "../../assets/images/hand-icon.png";
import { Button } from "@material-tailwind/react";
import sign from "../../assets/images/zigzag.png";
import "../../css/chatbot.css";
import {
  ArrowRightIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import FormInput from "../../components/FormInput";
import { emailValidation } from "src/utils";
import { Interview_Process, LANGUAGE } from "src/utils/constant";
import { useTranslation } from "react-i18next";
import { setLanguageValue } from "src/store/slices/language";
import Select from "react-select";
import Flag from "react-world-flags";
import {  useAppDispatch, useAppSelector } from "src/store/hooks";
import { updateUserLanguage } from "src/api/userLanguage";
import { toast } from "react-toastify";
interface PropsType {
  onClick: () => void;
  disableButton: boolean;
  siteData: urlDecodedDataProps;
  language:any;
  checkIsAttemptedRes?:any
}
export default function AwsWelcome({
  onClick,
  disableButton,
  siteData,
  language,

  checkIsAttemptedRes
}: PropsType) {
 const { i18n } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [isError, setError] = useState<boolean>(true);
   const dispatch = useAppDispatch();

  useEffect(() => {
    if (siteData) {
      setEmail(siteData.email);
      setName(siteData.name);
    }
  }, [siteData]);

  useEffect(() => {
    if (name && email && name?.trim() && email?.trim()) {
      if (!emailValidation(email)) {
        setError(true);
      } else {
        setError(false);
      }
    } else {
      setError(true);
    }
  }, [name, email]);

  const _checkUserInfo = () => {
    if (email && name) {
      onClick();
    }
  };

    const { t } = useTranslation();
      const customOptionLabel = (option:any) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Flag code={option.flag} style={{ width: 20, height: 15, marginRight: 8 }} />
          {option.name}
        </div>
      );
  const onChangeLang = (selectedOption:any) => {
    const selectedLanguage = selectedOption.value;
 // Change the language dynamically
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
    dispatch(setLanguageValue(selectedLanguage));
  
    let payload:any={
      language:selectedLanguage,
      claimId:siteData?.claimId
    }
       dispatch(updateUserLanguage(payload)).then((res: any) => {
          if (res?.payload?.statusCode === 200) {
            toast.success(res?.payload?.message);
          }
          if (res?.payload?.status === 500) {
            toast.error(res?.payload?.message);
          }
        });
  }
  return (
    <div className="border-[#DDD] w-full rounded-sm pb-5 h-[75vh] mt-6 pl-7">
      <div className="flex w-full h-full ">
        <div className="w-1/2 justify-center content-center  lg:block md:block">
          <div className="spangrey  lg:w-1/2 md:w-1/2 sm:w-full sm:!text-[14px] lg:!text-[18px] md:!text-[18px] ">
            <span className="flex  items-center">
              <span className="text-xl whitespace-nowrap flex">
                {" "}
                {t("CHAT_WITH_ELI")}{" "}
                <div className="rounded-full bg-[#0774D9] h-2 w-2 m-auto ml-2"></div>
              </span>
            </span>
          </div>
          <span className="flex  items-center pt-4 ">
            <span className="flex">
              {" "}
              {t("Hello")} &nbsp; <p className="font-semibold">{name}</p> ! &nbsp;{" "}
            </span>
            <img className="h-8 w-8 mt-1 " src={handIcon} alt="bot image" />
          </span>
          <span className="">
           {t("CHAT_WITH_ELI__DISCLAIMER")}
          </span>
          <img className="h-6 md:h-8 relative left-[50%] mt-2" src={sign} />
          <p className="text-[#1F7FBB] text-xl mb-8 pt-4 font-semibold">
            {t("FILL_Information")}
          </p>
          <FormInput
            icon={<EnvelopeIcon />}
            className="bg-white"
            value={email}
            label={t("Email")}
            required
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <label className={`text-xs font-medium mb-1 `}>
            {t("Preferred Language")}
            <span className="text-red-500"> *</span>
          </label>
          
          <Select
        placeholder={t("Select Language")}
       className="p-0 pr-[18px] text-sm text-blue-gray-700 w-full h-[38px] rounded-md bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 focus:border-1 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10"
        id="language"
        value={{ value: language, name: LANGUAGE.find((option: any) => option.value === language)?.name,flag:LANGUAGE.find((option: any) => option.value === language)?.flag }}
        options={LANGUAGE}
        onChange={onChangeLang}
        formatOptionLabel={customOptionLabel}
        styles={{
          control: (provided) => ({
            ...provided,
            backgroundColor: "white", // Set the background color htmlFor the control
          }),
          option: (provided) => ({
            ...provided,
            color: "black",
            backgroundColor: "transparent", // Set the background color htmlFor the options
          }),
        }}
      />
      
          <Button
            onClick={_checkUserInfo}
            disabled={isError|| Interview_Process.filter(item=>item===checkIsAttemptedRes?.data?.attemptedMediumType).length>0 }
            style={{
              flex: 1,
              // backgroundColor: "#207FBB",
              color: "#FFF",
              marginTop: 60,
              paddingLeft: 40,
              paddingRight: 40,
            }}
            className=" flex get__started__btn items-center gap-3 text-[14px] disabled:cursor-not-allowed"
          >
            {t('Continue')}
            <ArrowRightIcon color="#FFF" width={25} height={25} />
          </Button>
        </div>

        {
          <div className="h-[80vh] lg:w-1/2   :w-1/2 sm:w-[800px] p-4 pl-7  ">
            <img
              className="h-3/4 w-3/4 object-cover object-center self-center m-auto"
              src={botImg}
              alt="bot image"
            />
          </div>
        }
      </div>
    </div>
  );
}
