import React, { useCallback, useEffect, useState } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

interface Iprops {
  ref?: any;
  id?: string;
  required?: boolean;
  className?: any;
  value?: any;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
  label?: any;
  key?: any;

  onBlur?: (value: any) => void;
  onChange: (value: any) => void;
  onFocus?: (value: any) => void;
}
const CountryCodeInput = ({
  required,
  className,
  value,
  label,
  disabled,
  onBlur,
  onFocus,
  onChange,
}: Iprops) => {

  const handlePhoneChange = (value: any) => {
    onChange(value)
  };
  const [isTouched, setIsTouched] = useState(false);
  const [hasError, setHasError] = useState(false);
  const handleBlur = useCallback(
    (e: any) => {
      setIsTouched(true);
      if (!value) {
        setHasError(true);
      } else {
        setHasError(false);
      }

      if (onBlur) onBlur(e);
    },
    [value, onBlur]
  );
  const handleFocus = useCallback(
    (e: any) => {
      setIsTouched(true);
      if (onFocus) onFocus(e);
    },
    [onFocus]
  );
  const renderLabel = () => (
    <label
      className={`text-xs font-medium mb-2  ${hasError && isTouched && !value && required ? "text-red-500" : ""}`}
    >
      {label}
      {required && <span className="text-red-500"> *</span>}
    </label>
  );
  return (
    <div className="flex flex-col">

      {label && renderLabel()}

      <PhoneInput
        defaultCountry="US"
        value={value}
        className={`${className} disabled:bg-blue-gray-50`}
        onChange={handlePhoneChange}
        onBlur={handleBlur}
        disabled={disabled}
        onFocus={handleFocus}
        inputStyle={{border:'2px solid #CECECE' }} 
      />
    </div>
  );
};

export default CountryCodeInput;
