import axios from "axios";
import BASE_URL from "./ApiUrl";

const axiosInstance = axios.create({
  baseURL: BASE_URL.defaultUrl,
  headers: {
    // "Content-Type": "application/json",
  },
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest?._retry) {
      originalRequest._retry = true;

      try {
        let data: any = localStorage.getItem("UserInfo");
        let refreshNewToken;
        if (data) {
          data = JSON.parse(data);
          refreshNewToken = data?.refreshToken;
        }

        const response = await axiosInstance.post(
          "/auth/refresh?refreshToken=" + refreshNewToken,
          ""
        );
        if (response.status === 200) {
          const newAccessToken = response.data.token;
          data.token = newAccessToken;
          localStorage.setItem("UserInfo", JSON.stringify(data));
          // // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        }
      } catch (error) {
        console.log(error);
        // Handle refresh token error or redirect to login
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
