import React,{useState,useEffect} from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LANGUAGE } from "src/utils/constant";
import { setLanguageValue } from "src/store/slices/language";
import Select from "react-select";
import Flag from "react-world-flags";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { RootState } from "src/store";
interface PropsState{
  hideHeader?:boolean
}

export const SidebarHeader = () => (
    <div className="flex items-center justify-between h-[74px] gap-2 px-6 py-3.5 lg:py-3.5 bg-white">
      <div className="w-full flex justify-center items-center">
        <img
          src="/images/final_logo_1.png"
          alt="Logo"
          className={`w-[200px] relative top-[10px] lg:align-middle mx-auto h-[40px]`}
        />
      </div>
    </div>
  );
  
const InterviewSchedlueLayout: React.FC<PropsState> = ({hideHeader}) => {
  const dispatch = useAppDispatch();
    const { i18n } = useTranslation();
  const customOptionLabel = (option:any) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Flag code={option.flag} style={{ width: 20, height: 15, marginRight: 8 }} />
      {option.name}
    </div>
  );
  const onChangeLang = (selectedOption:any) => {
    const selectedLanguage = selectedOption.value;
 // Change the language dynamically
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
    dispatch(setLanguageValue(selectedLanguage));
  };
;
  useEffect(() => {
    if (localStorage.getItem("language")) {
      dispatch(setLanguageValue(localStorage.getItem("language")));
    }
  }, [0]);
  const { language } = useAppSelector((state: RootState) => state.language);

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark bg-white font-verdana h-[100vh]">
      <div className=" h-screen overflow-hidden">
      <header className={`sticky top-0 z-50 flex w-full h-[8%]"`}>
      <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11 h-[67px]">
       <SidebarHeader />
       <Select
        placeholder="Select Language"
       className="p-0 pr-[18px] text-sm text-blue-gray-700 w-[20%] h-[38px] rounded-md bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 focus:border-1 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10"
        id="language"
        value={{ value: language, name: LANGUAGE.find((option: any) => option.value === language)?.name,flag:LANGUAGE.find((option: any) => option.value === language)?.flag }}
        options={LANGUAGE}
        onChange={onChangeLang}
        formatOptionLabel={customOptionLabel}
        styles={{
          control: (provided) => ({
            ...provided,
            backgroundColor: "white", // Set the background color htmlFor the control
          }),
          option: (provided) => ({
            ...provided,
            color: "black",
            backgroundColor: "transparent", // Set the background color htmlFor the options
          }),
        }}
      />
      </div>
    </header>
      {<div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden h-[110vh]">
       
          <main className="flex-1">
            <div className={`mx-auto max-w-screen bg-white h-[90vh] overflow-y-auto ${hideHeader?"":"p-2 md:p-4 2xl:p-6"}`}>
              <Outlet />
            </div>

          </main>
        </div>}
        
      </div>
    </div>
  );
};

export default InterviewSchedlueLayout;
