import { ViewColumnsIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@mui/material";
import{updateColumnVisibility} from "src/store/slices/dashboardColumnSlice"
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { RootState } from "src/store";
interface props {
  updateColumnVisibilityFun: (key: any, value: boolean) => void;
}
const HideShowColoum = ({ updateColumnVisibilityFun }: props) => {
  const dispatch = useAppDispatch();
  const selectedValues = useAppSelector((state: RootState) => state.dashboardColumn.selectedValues);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  // List of options for the dropdown
  const options = [
    { value: "fileNumber", label: t("Ampcus File Number") },
    { value: "claimNumber", label: t("Claim Number") },
    { value: "clientName", label: t("Client Name") },
    { value: "insuredBusinessName", label: t("Insured Business Name") },
    { value: "status", label: t("Status") },
    { value: "assignee", label: t("Assigned Accountant") },
    { value: "emailSent", label: t("Email Status") },
    { value: "initiateReport", label: t("Initiate Report") },
    { value: "dateTimeCreated", label: t("DateTime Created") },
    { value: "dateTimeUpdated", label: t("DateTime Updated") },
  ];

  const handleCheckboxChange = (value: string) => {
    if (selectedValues.includes(value)) {
        dispatch(updateColumnVisibility(selectedValues.filter((item) => item !== value)));
        updateColumnVisibilityFun(value, false);
    } else {
        dispatch(updateColumnVisibility([...selectedValues, value]));
        updateColumnVisibilityFun(value, true);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ position: "relative", width: "200px" }}>
      <button
        onClick={toggleDropdown}
        style={{ width: "100%", padding: "10px" }}
        className="bg-[#eaeaea] p-2 rounded-md -mt-4 whitespace-nowrap flex items-center"
      >
        <ViewColumnsIcon className="mr-2 w-6 h-6" /> Manage Columns


      </button>

      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "40px",
            left: "0",
            width: "120%",
            background: "white",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            maxHeight: "200px",
            overflowY: "auto",
            zIndex: 10,
          }}
        > <div className="flex justify-end  mr-2 absolute right-0 cursor-pointer mt-2" onClick={toggleDropdown}>
            <XMarkIcon className="w-6" /></div>
          {options.map((option, key) => (
            <label key={key} style={{ display: "block", padding: "10px" }}>
              <Checkbox
                className="h-6 w-6 border-2 border-black"
                checked={selectedValues.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value)}
              />
              <span className="font-poppins text-sm font-medium">{option.label}</span>
            </label>
          ))}
       
        </div>
      )}
    </div>
  );
};

export default HideShowColoum;
