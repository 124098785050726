import { createSlice } from "@reduxjs/toolkit";

interface LanguageState {
    language: string;
}

const initialState: LanguageState = {
    language: "en",
   
  };
  
const languageSlice = createSlice({
    name: "language",
    initialState,
    reducers: {
        setLanguageValue:(state,action)=>{
            if(action.payload==null){
              state.language="en"
            }else{
            state.language=action.payload
            }
          },
    }
});

export const {setLanguageValue} = languageSlice.actions;
export default languageSlice.reducer;