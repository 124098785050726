/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Button } from "@material-tailwind/react";
import {
  ArrowRightIcon
} from "@heroicons/react/24/outline";
import sign from "../../assets/images/zigzag.png";
import { Interview_Mode, Interview_Process } from "src/utils/constant";
import { useTranslation } from 'react-i18next';
interface props{
  handleHideIntro: () => void;
  disableButton:boolean;
  checkIsAttemptedRes?:any

}
const InitialPage = ({handleHideIntro,disableButton,checkIsAttemptedRes}:props) => {
  const interviewMode:any = checkIsAttemptedRes?.data?.attemptedMediumType ||""
   const { t } = useTranslation();
  return (
    <div className="h-auto min-h-[70vh] flex flex-col md:flex-row ">
      <div className="flex-1 hidden md:block">
        <div className="spangrey  lg:w-1/2 md:w-1/2 sm:w-full p-4 pl-7 sm:!text-[14px] lg:!text-[18px] md:!text-[18px] ">
          <span className="flex  items-center pt-12 ">
            <span className="text-xl whitespace-nowrap flex">
              {" "}
              {t("Interview Assessment")} <div className="rounded-full bg-[#0774D9] h-2 w-2 m-auto ml-2"></div>
            </span>
          </span>
        </div>
        {<p className="pl-7 font-poppins text-lg font-semibold p-4 text-justify">
          {t("To ensure a through and accurate review, we need to gather additional information about your business operations and the specifics of the claim.")}
        </p>}
        <img className="h-8 md:h-8 relative left-[50%]" src={sign}/>
       {Interview_Process.filter(item=>item===checkIsAttemptedRes?.data?.attemptedMediumType).length>0 && checkIsAttemptedRes?.data?.attempt>0 && <p className="text-[#f50404] pl-7 font-semibold">Note: <span className="">{checkIsAttemptedRes?.message } with  {Interview_Mode[interviewMode]}</span></p>}
        <Button
          onClick={() => handleHideIntro()}
          style={{
            flex: 1,
            // backgroundColor: "#207FBB",
            color: "#FFF",
        
          }}
          disabled={Interview_Process.filter(item=>item===checkIsAttemptedRes?.data?.attemptedMediumType).length>0 }
          className="flex m-auto text-[14px] get__started__btn m-9 !disabled:cursor-not-allowed disabled:bg-[#9a9ea1]"
        >
          {t('Continue')}
        </Button>
      
      </div>
      <div className="flex-1 block items-center content-center justify-center relative">
        <div className="vector_img"></div>
        {/* <div className="vector"></div> */}
      </div>
    </div>
  );
};

export default InitialPage;
