/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import React, { useEffect, useState } from "react";
import handIcon from "../../assets/images/hand-icon.png";
import FormInput from "src/components/FormInput";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { emailValidation } from "src/utils";
import { toast } from "react-toastify";
import { login } from "src/api/user";
import { RootState } from "src/store";
import InlineLoader from "src/components/loader/InlineLoader";
export default function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { userData, message, error, loading } = useAppSelector(
    (state: RootState) => state.user
  );

  // useEffect(()=>{
  //   getLocalUser()
  // },[])
  // const doLogin=()=>{
  //   window.location.href = "http://localhost:8086/api/auth/authenticate";
  // }

  // const doLogin = async () => {
  //   const response = await fetch('http://localhost:8086/api/auth/register', {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     // body: JSON.stringify({ username, password }),
  //   });

  //   if (response.ok) {
  //     // If registration is successful, redirect to login or home
  //     window.location.href = "/test"; // Or wherever you want to redirect
  //   } else {
  //     // Handle error
  //     alert('Registration failed');
  //   }
  // };
  useEffect(() => {
    if (error) {
    }
    if (!userData && message) {
      toast.error(message);
    }
    // if(user && user.id){
    //   toast.success(message)
    // navigate("/dashboard")
    // }
  }, [userData, error, message, loading]);

  const _doLogin = () => {
    if (email?.trim().length > 0) {
      if (!emailValidation(email)) {
        toast.warning("Please enter valid email.");
        return;
      }
    } else if (email?.trim().length == 0) {
      toast.warning("Please enter email.");
      return;
    }
    if (password?.trim()?.length == 0) {
      toast.warning("Please enter password ");
      return;
    } else if (password?.trim()?.length < 5) {
      toast.warning("Password must be atleast 5 character long. ");
      return;
    }

    dispatch(login({ username: email, password }));
  };
  return (
    <div>
      <div className="min-h-screen flex flex-col md:flex-row ">
        <a className="absolute top-3 left-5">
          <img
            className="w-40 hidden md:block"
            src="/images/final_logo_1.png"
          />
          <img
            className="w-36 block md:hidden"
            src="/images/final_logo_1.png"
          />
        </a>
        <div className="flex-1 hidden md:block bg-cover bg-no-repeat bg-login border-r-2 br-pos">
          <div
            className="w-full text-xs md:text-sm bg-opacity-10 text-justify  py-3 px-10 max-w-xl text-center mx-auto mt-20 rounded-2xl text-[#1F7FBB] "
            id="border-red1"
          >
            Forensic accounting is a specialized field that combines accounting,
            auditing, and investigative skills to examine financial crimes and
            provide legal evidence.
          </div>
          <div className="upperpolygon"></div>
          <div className="downpolygon"></div>
        </div>
        <div className="flex-1 xl:gradient-pos flex items-center content-center justify-center ">
          <div className="max-w-xl mx-auto w-full px-5 py-15" id="formReg">
            <div className="">
              <div className="w-full flex flex-col mb-1 ">
                <div className="w-full flex items-center mb-1 justify-center">
                  <h2 className="flex font-medium text-2xl sm:text-2xl flex-1  ">
                    Welcome back{" "}
                    <img
                      className="h-8 w-8 mt-1 "
                      src={handIcon}
                      alt="bot image"
                    />
                  </h2>
                </div>
                <p className="text-gray-600 mb-8">
                  Sign in to your account to be with us again.
                </p>
                <div className="mb-1">
                  <div className="flex-1 w-full">
                    <label
                      htmlFor="email"
                      className="text-black block mb-2 font-bold"
                    >
                      Email*
                    </label>
                    <FormInput
                      required
                      value={email}
                      type="text"
                      placeholder="Enter Your Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mb-1">
                  <div className="flex-1 w-full relative">
                    <label
                      htmlFor="Password"
                      className="text-black block mb-2 font-bold"
                    >
                      Password*
                    </label>
                    <FormInput
                      required
                      type="password"
                      placeholder="Enter Your Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex mt-2 mb-3 w-full flex-col lg:flex-row md:justify-between">
                  {/* <div className="flex flex-row">
                    <div className="font-light flex-none text-sm tracking-normal">
                      Don't have an account yet?
                    </div>
                    <a
                      href="#"
                      className="font-bold flex-none text-blue-500 text-sm ml-2 hover:underline transition tracking-normal"
                    >
                      Sign up
                    </a>
                  </div> */}
                  <div/>
                  <a
                    onClick={() => navigate("forget-password")}
                    className="font-bold flex-none text-blue-500 text-sm  hover:underline transition tracking-normal hover:cursor-pointer"
                  >
                    Forgot Password?
                  </a>
                </div>
                <button
                  type="submit"
                  onClick={_doLogin}
                  className="font-bold text-lg mb-3 py-3 border-b-4 border border-blue-500 bg-blue-100 text-black block w-full rounded-lg hover:bg-blue-500 hover:text-white transition"
                  id="flowBtn"
                >
                  Log In
                </button>
                <div className="text-xs font-light text-gray-500 tracking-normal">
                  By creating an account you accept the{" "}
                  <a className="font-bold text-blue-500 hover:underline transition">
                    {" "}
                    Terms of Use{" "}
                  </a>{" "}
                  and{" "}
                  <a className="font-bold text-blue-500 hover:underline transition">
                    {" "}
                    Privacy Policy
                  </a>
                </div>
                {/* </form> */}
                {loading && (
                  <span className="z-20 m-auto mt-2">
                    <InlineLoader />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
