import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { ErrorOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
interface propsData {
  handleOpen: () => void;
  open: boolean;
  message?: string;
  title?: string;
  body?: any;
  handleSave?: () => void;
  handleDelete?: () => void;
}
export function DialogCustomAnimation({
  handleOpen,
  open,
  message,
  title,
  body,
  handleSave,
  handleDelete,
}: propsData) {
  //   const [open, setOpen] = React.useState(false);

  //   const handleOpen = () => setOpen(!open);
  const { t } = useTranslation();
  return (
    <>
      {/* <Button onClick={handleOpen} variant="gradient">
        Open Dialog
      </Button> */}
      <div className="mb-3 flex gap-3">
        <Dialog
          open={open}
          size={"xs"}
          handler={handleOpen}
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          {!body && title && (
            <DialogHeader>
              <ErrorOutline /> {title}
            </DialogHeader>
          )}
          <DialogBody>{message || body}</DialogBody>
          <DialogFooter>
            {handleSave && (
              <Button
                variant="primary"
                color="blue"
                onClick={handleSave}
                className="mr-1"
              >
                <span>{t("Save")}</span>
              </Button>
            )}

            {handleDelete && (
              <Button
                variant="primary"
                color="red"
                onClick={handleDelete}
                className="mr-1"
              >
                <span>{t("Delete")}</span>
              </Button>
            )}
            {handleDelete && (
              <Button
                variant="outline"
                color="blue"
                onClick={handleOpen}
                className="mr-1"
              >
                <span>{t("Cancel")}</span>
              </Button>
            )}
            {!handleDelete && (
              <Button
                variant="outline"
                color="red"
                onClick={handleOpen}
                className="mr-1"
              >
                <span>{t("Cancel")}</span>
              </Button>
            )}
            {/* <Button variant="gradient" color="green" onClick={handleOpen}>
            <span>Confirm</span>
          </Button> */}
          </DialogFooter>
        </Dialog>
      </div>
    </>
  );
}
