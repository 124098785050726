/* eslint-disable no-whitespace-before-property */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Tabs from "../../modules/InterviewForAdjuster/Tabs";
import chatbot from "../../assets/images/chatbot.png";
import talkBot from "../../assets/images/talkbot.png";
import talkAccountant from "../../assets/images/talkAccountant.png";
import questionIcon from "../../assets/images/questionnaire_icon.png";
import { useState } from "react";
import { Interview_Process, Interview_Tabs, TIME, TIME_SLOTS } from "../../utils/constant";
import AwsChatbot from "../../modules/aws-lex/AwsChatbot";
import TalkWithBot from "../../modules/talkWithBot";
import Interview from "../../modules/InterviewForAdjuster/interview/index";
import CryptoJS from "crypto-js";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { RootState } from "src/store";
import { toast } from "react-toastify";
import Loader from "src/components/loader";
import SuccessPath from "src/Features/successPath";
import { clearSelection } from "src/store/slices/botSlice";
import {
  attemptInterview,
  editSchedule,
  scheduleInterview,
  checkIsAttempted,
  getScheduledEventList,
} from "src/api/interview";
import { saveTranscriptDocumets } from "src/api/documentApi";
import { resetChatBotData, setMessagesValue, updateChatBot } from "src/store/slices/chatBotSlice";
import InitialPage from "src/modules/InterviewForAdjuster/scheduleInterview/initialPage";
import arrowBack from "../../../src/assets/images/Left Arrow.png";
import { resetAttempt, resetSchedule } from "src/store/slices/interviewSlice";
import moment from "moment";
import { convertToEST } from "src/utils";
import { useTranslation } from 'react-i18next';
import { setLanguageValue } from "src/store/slices/language";

// import { resetChatBotData, setMessagesValue } from "src/store/slices/chatBotSlice";
// import { DialogCustomAnimation } from "src/components/dialog";
const StartInterviewForAdjuster = (props?: any) => {
  const [siteData, setSiteData] = useState<any>();
   const { t ,i18n} = useTranslation();
  // const [readyToRender,setReadyToRender] = useState<string>("In Progress");
  const { data } = useParams<string>();
  const dispatch = useAppDispatch<any>();
  const [successMessage, setSuccesMessage] = useState<boolean>(false);
  const [timeZones, setTimeZones] = useState<TimeZone[]>([]);
   const [step, setStep] = useState<any>(1);
   const [accountantMessage,setAccountantMessage]= useState("")
  // const [open,setOpen]=useState(false)
  const {
    error,
    message,
    loading,
    checkIsAttemptedLoading,
    checkIsAttemptedRes,
  } = useAppSelector((state: RootState) => state.interview);

  const {
    loading: docLoading,
    message: docMessage,
    error: docError,
  } = useAppSelector((state: RootState) => state.documents);
  // const formValue = useAppSelector((state: RootState) => state.talkBot);

  const { comments, date,time,userTimeZone, timeZone,slotAvailable } = useAppSelector(
    (state: RootState) => state.talkBot
  );

  React.useEffect(() => {
    if (data && !siteData) {
      decrypt1();
    }
  }, [data]);
  React.useEffect(()=>{
    if(checkIsAttemptedRes?.data?.attempt>0){
      setStep(2)
    }
  },[checkIsAttemptedRes])

  React.useEffect(() => {
    if (timeZone) {
      const timeZoneData: any = timeZones.find(
        (zone) => zone.name === timeZone
      );
      const finalEndDate = getSchudalFormattedDate(date, timeZoneData.value);
      // if(!date) return
      let date1 = new Date(finalEndDate);
      const endDate = date1;
      endDate.setDate(date1.getDate() + 1);
      const startDate = date;
      const payload = {
        startDateTime: startDate?.toISOString(),
        endDateTime: endDate?.toISOString(),
      };
      dispatch(getScheduledEventList(payload));
    }
  }, [timeZone,date]);

  React.useEffect(() => {
    if (siteData) {
      dispatch(resetAttempt())
      dispatch(checkIsAttempted({ claimId: siteData.claimId }));
      i18n.changeLanguage(siteData.language);
      localStorage.setItem("language", siteData.language);
      dispatch(setLanguageValue(siteData.language));
    }
  }, [siteData]);

  const attemptInterViewOnClick = () => {
    const { authToken, claimNumber, tempPassword } = siteData;
    const payload: attemptInterview = {
      authToken: authToken,
      claimsId: claimNumber,
      tempPassword: tempPassword,
    };
    dispatch(attemptInterview(payload));
  };

  React.useEffect(() => {
    fetch("/timezones.json")
      .then((response) => response.json())
      .then((data) => setTimeZones(data))
      .catch((error) => console.error("Error loading timezones:", error));
  }, []);


  React.useEffect(() => {
    if (error && message) {
      toast.error(message);
      // setOpen(true)
    } else if (!error && message) {
      setSuccesMessage(true);
      // setOpen(false)
      toast.success(message);
    }
  }, [message, error]);

  React.useEffect(() => {
    if (docError && docMessage) {
      toast.error(docMessage);
      // setOpen(true)
    } else if (!docError && docMessage) {
 
      setSuccesMessage(true);
      // setOpen(false)
      toast.success(docMessage);
    }
  }, [docMessage, docError]);

  const decrypt1 = () => {
    try {

      var finalData = data ? decodeURIComponent(data) : "";
      var base64EncodedKeyFromJava = "QUJDREVGR0hJSktMTU5PUA==";
      var keyForCryptoJS = CryptoJS.enc.Base64.parse(base64EncodedKeyFromJava);
      var decodeBase64 = CryptoJS.enc.Base64.parse(finalData);

      var decryptedData = CryptoJS.AES.decrypt(
        {
          ciphertext: decodeBase64,
        },
        keyForCryptoJS,
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      var decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);

      let oldMsg: any = localStorage.getItem("CHAT_BOT_DATA");
      const newSiteData = JSON.parse(decryptedText);  

      if ( oldMsg !== "" && oldMsg !=null) {   
        if (Object.keys(JSON.parse(oldMsg))[0]  !== newSiteData?.claimNumber) {
          localStorage.setItem("CHAT_BOT_DATA", JSON.stringify(""));
          dispatch(setMessagesValue(null));
        }
        // else{
          // dispatch(updateChatBot(Object.values(JSON.parse(oldMsg))[0]))
        // }
      }
      setSiteData(newSiteData);
     
    } catch (e) {
      console.log(e)
      // setReadyToRender("URL information is not valid");
    }
  };

  const [activeTab, setActiveTab] = useState<any>("");

  // React.useEffect(() => {
  //   if (checkIsAttemptedRes && checkIsAttemptedRes?.data) {
  //     setActiveTab(Interview_Tabs.ACCOUNTANT_CALL)
  //   } else if (!docError && docMessage) {
  //     setActiveTab(Interview_Tabs.CHAT_WITH_BOT)
  //   }
  // }, [checkIsAttemptedRes ]);
  
React.useEffect(()=>{
  dispatch(resetChatBotData())
},[activeTab])
  const onClickActionButton = ({
    data,
    actionType,
    moduleType,
  }: InterViewProps) => {
    if (actionType === "SUBMIT") {
      const { date, time, dateTime, timeZone,message } = data;
      let errorFileds = "";
      if(!slotAvailable){
      if (!date || !time) {
        errorFileds += " Date & Time,";
      }
      if (timeZone?.trim()?.length === 0) {
        errorFileds += " Time Zone";
      }
      if (errorFileds) {
        toast.error("Please select " + errorFileds);
        return;
      }
    }else{
      if(!message){
        toast.error("Please enter your preffered interview slot details ");
        return;
      }
    }
    
    }

    if (moduleType === "TALK_WITH_BOAT") {
      if (actionType === "SUBMIT") {
        callScheduleApi({ data, moduleType });
      }
    } else if (moduleType === "TALK_WITH_ACCOUNTANT") {
      if (actionType === "SUBMIT") {
        callScheduleApi({ data, moduleType });
      }
    }
  };

  const getSchudalFormattedDate = (date: Date, timeZone: string) => {
    const ianaTimeZone = timeZone;
    if (!ianaTimeZone) {
      throw new Error(`Invalid time zone: ${timeZone}`);
    }

    // Format the date in the selected time zones
    const formatter = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: ianaTimeZone,
    });

    const [
      { value: month },
      ,
      { value: day },
      ,
      { value: year },
      ,
      { value: hour },
      ,
      { value: minute },
    ] = formatter.formatToParts(date);

    const finalDate = `${year}-${month}-${day}T${hour}:${minute}:00`;

    return finalDate;
  };

  const callScheduleApi = ({ data, moduleType }: InterViewProps) => {
    const { dateTime, timeZone, date, time,message } = data;
    const timeZoneData:any = timeZones.find((zone) => zone.name === timeZone);
    if (!siteData) return;
    const {
      name,
      authToken,
      claimNumber,
      email,
      forensicAssignee,
      insuredBusinessName,
      clientFirstName,
      clientLastName,
    }: urlDecodedDataProps = siteData;
    if(slotAvailable){
      const payload: InterViewSchedule = {
        interviewType: moduleType,
        insuredEmail: email,
        insuredName: name,
        forensicAssignee: forensicAssignee,
        interviewStartTime: "",
        interviewEndTime:"",
        timezone: "EST",
        userTimeZone: "",
        interviewStatus: "DRAFT",
        authToken: authToken,
        message: comments,
        claimsId: claimNumber,
        insuredBusinessName: insuredBusinessName,
        clientFirstName: clientFirstName,
        clientLastName: clientLastName,
       
      };
      dispatch(scheduleInterview(payload));
      return
    }

  
    if (!timeZoneData) {
      console.error("Timezone not found in the provided data");
      return;
    }
   

    let dateString = moment(date).format("YYYY-MM-DD");  // format: YYYY-MM-DD
    let timeString = time;    // format: HH:MM:SS
    let combinedDateTimeString = (dateString + "T" + timeString); 
    const start_date = new Date(combinedDateTimeString);
    const end_date = moment(new Date(start_date.getTime() + 30 * 60000)).format("HH:mm:ss");
    const finalStartDate = convertToEST(
      moment(date).format("YYYY-MM-DD"),timeString,
      timeZoneData.value
    );
    const finalEndDate = convertToEST(
      moment(date).format("YYYY-MM-DD"),end_date,
      timeZoneData.value
    );
    const payload: InterViewSchedule = {
      interviewType: moduleType,
      insuredEmail: email,
      insuredName: name,
      forensicAssignee: forensicAssignee,
      interviewStartTime:  moment(finalStartDate?.formattedDateTime).format("YYYY-MM-DD")+"T"+moment(finalStartDate?.formattedDateTime).format("HH:mm:ss"),
      interviewEndTime: moment(finalEndDate?.formattedDateTime).format("YYYY-MM-DD")+"T"+moment(finalEndDate?.formattedDateTime).format("HH:mm:ss"),
      timezone: "EST",
      userTimeZone: timeZoneData.abbreviation,
      interviewStatus: "DRAFT",
      authToken: authToken,
      message: comments,
      claimsId: claimNumber,
      insuredBusinessName: insuredBusinessName,
      clientFirstName: clientFirstName,
      clientLastName: clientLastName,
     
    };
    setAccountantMessage(moment(finalStartDate?.formattedDateTime).format("MMMM Do YYYY")+" "+moment(finalStartDate?.formattedDateTime).format("HH:mm") + " - "+ moment(finalEndDate?.formattedDateTime).format("HH:mm")+"("+timeZoneData.abbreviation+")")
    // attemptInterViewOnClick()

    dispatch(scheduleInterview(payload));
  };



  const handleEditSchedule = async ({
    item,
    data,
    moduleType,
  }: InterViewProps) => {
    try {
      await editScheduleApi({ item, data, moduleType }); // Ensure it's correctly referenced
    } catch (error) {
      console.error("Error calling edit schedule API:", error);
    }
  };

  const editScheduleApi = ({ item, data }: InterViewProps) => {
    const { dateTime, timeZone } = data;
    const timeZoneData:any = timeZones.find((zone) => zone.name === timeZone);

    if (!timeZoneData) {
      console.error("Timezone not found in the provided data");
      return;
    }

    if (!siteData) return;

    // const { authToken }: urlDecodedDataProps = siteData;
    const {
      name,
      authToken,
      claimNumber,
      email,
      forensicAssignee,
      insuredBusinessName,
      clientFirstName,
      clientLastName,
    }: urlDecodedDataProps = siteData;

    let dateString = moment(date).format("YYYY-MM-DD");  // format: YYYY-MM-DD
    let timeString = time;    // format: HH:MM:SS
    let combinedDateTimeString = (dateString + "T" + timeString); 
    const start_date = new Date(combinedDateTimeString);
    const end_date = moment(new Date(start_date.getTime() + 30 * 60000)).format("HH:mm:ss");
    const finalStartDate = convertToEST(
      moment(date).format("YYYY-MM-DD"),timeString,
      timeZoneData.value
    );
    const finalEndDate = convertToEST(
      moment(date).format("YYYY-MM-DD"),end_date,
      timeZoneData.value
    );
    if(finalStartDate?.formattedDateTime ==="Invalid DateTime" || finalEndDate?.formattedDateTime ==="Invalid DateTime"){
      toast.error("Please select date and time")
      return
    }
    const payload: InterViewSchedule = {
      id: item.id,
      insuredEmail: item.insuredEmail,
      interviewScheduledWithEmail: item.interviewScheduledWithEmail,
      insuredPhone: item.insuredPhone,
      countryCode: item.countryCode,
      insuredName: item.insuredName,
      ccEmailId: item.ccEmailId,
      interviewType: item.interviewType,
      interviewStartTime:  moment(finalStartDate?.formattedDateTime).format("YYYY-MM-DD")+"T"+moment(finalStartDate?.formattedDateTime).format("HH:mm:ss"),
      interviewEndTime: moment(finalEndDate?.formattedDateTime).format("YYYY-MM-DD")+"T"+moment(finalEndDate?.formattedDateTime).format("HH:mm:ss"),
      userTimeZone: timeZoneData.abbreviation,
      timezone:"EST",
      interviewStatus: item.interviewStatus,
      authToken: authToken,
      claimsId: item.claimsId,
      insuredBusinessName: insuredBusinessName,
      clientFirstName: clientFirstName,
      clientLastName: clientLastName,
    };
    dispatch(editSchedule(payload));
  };

  const _onSessionEnd = async (lastMessageObj?: any) => {
    if (!siteData) return;
    const { authToken, claimId }: urlDecodedDataProps = siteData;
    let fileName = lastMessageObj.transcript_link?.split("/");
    if (fileName) {
      fileName = fileName[fileName.length - 1];
    }
    const payload: any = {
      authToken: authToken,
      claimId: claimId,
      downloadUrl: lastMessageObj.transcript_link, //claimNumber + "_session_attributes.xlsx",
      cloud: "AWS",
      type: "Transcript",
      filename: fileName,
      transcriptOneDriveFileId: lastMessageObj.transcript_file_id, //will get in the response of chatbot
    };

    dispatch(saveTranscriptDocumets(payload)).then(() => {
      attemptInterViewOnClick();
      setTimeout(()=>{
        dispatch(checkIsAttempted({ claimId: siteData.claimId }));
      },1000)
        });
  };

  React.useEffect(() => {
    if (checkIsAttemptedRes?.data?.attempt === 0) {
      setSuccesMessage(false);

      dispatch(clearSelection());
    }
  }, [activeTab]);
  React.useEffect(() => {
    if (siteData || activeTab==="") {
      dispatch(checkIsAttempted(siteData));
    }
  }, [siteData,activeTab]);
  const tabs= [
    {
        title:t("Chat with Eli"),
        icon:chatbot,
        description:t("Eli will ask questions about your claim, and you can provide Eli with the details of your loss."),
        buttonText:t("Start ChatBot"),
        selection:Interview_Tabs.CHAT_WITH_BOT
     
    },
    {
        title:t("Call Eli"),
        icon:talkBot,
        description:t("You can reach out to Eli and share the details of your loss through either a web call or a phone call."),
        buttonText:t("Call Now"),
        selection:Interview_Tabs.TALK_WITH_BOT
    },
    {
        title:t("Talk To our Accountant"),
        icon:talkAccountant,
        description:t("You will receive a call from our accounting team at your preferred date & time to provide the information regarding your loss."),
        buttonText:t("Schedule Interview"),
        selection:Interview_Tabs.ACCOUNTANT_CALL
    },
    {
        title:t("Claim Intake"),
        icon:questionIcon,
        description:t("You can complete a questionnaire to give further details about your loss."),
        buttonText:t("Fill out the form"),
        selection:Interview_Tabs.QUESTIONNAIRE
    }
]

  return (
    <div
      className={`mx-auto ${activeTab !== "" && "container overflow-x-hidden"}`}
    >
      {(loading || docLoading || checkIsAttemptedLoading) && <Loader />}
      {activeTab === "" && (
        <InitialPage
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          {...props}
          checkIsAttemptedRes={checkIsAttemptedRes}
          setStep={setStep}
          step={step}
          tabs={tabs}
        />
      )}
      {/* {(Interview_Tabs.TALK_WITH_BOT === activeTab || Interview_Tabs.ACCOUNTANT_CALL === activeTab) &&    
         <div className="lg:flex md:flex sm:grid lg:justify-between md:justify-between text-sm pl-4 mb-2 ml-[10%] mr-[10%]">
        <p className="whitespace-nowrap text-sm font-medium">
          Insured Business Name: &nbsp;
          <span className="text-[#1F7FBB] font-semibold">{siteData?.buisnessName}</span>
        </p>

          <p className="whitespace-nowrap text-sm font-medium">       Claim Number: &nbsp;
            <span className="text-[#1F7FBB] font-semibold">#{siteData?.claimNumber}</span>
          </p>
        </div>}
        <Tabs setActiveTab={setActiveTab} activeTab={activeTab} {...props} 
        /> */}
      {activeTab !== "" && (
        <div className="flex justify-between">
          {" "}
          <div className="block pl-6">
          {
          // checkIsAttemptedRes?.data?.attempt === 0 &&  
          <div
              className="flex mb-2 text-base font-semibold cursor-pointer"
              onClick={() => {setActiveTab("")
               dispatch(resetSchedule())

              }}
            >
              {" "}
              <img src={arrowBack} width={"40px"} /> {t("Back")}
            </div>
             }
            <p className="font-semibold text-2xl  g ml-2 whitespace-nowrap">
             {tabs.find(item=>item.selection===activeTab)?.title}
            </p>
            <div className="line rounded-xl !w-[500px]"></div>
          </div>
          <div className="grid sm:grid text-sm pl-4 mb-2 ml-[10%] mr-[10%]">
            <p className="whitespace-nowrap text-sm font-medium">
              {t("Insured Business Name")}: &nbsp;
              <span className="text-[#1F7FBB] font-semibold">
                {siteData?.buisnessName}
              </span>
            </p>

            <p className="whitespace-nowrap text-sm font-medium">
              {" "}
              {t("Claim Number")}: &nbsp;
              <span className="text-[#1F7FBB] font-semibold">
                #{siteData?.claimNumber}
              </span>
            </p>
          </div>
        </div>
      )}
      {Interview_Tabs.CHAT_WITH_BOT === activeTab &&
        ((!successMessage && checkIsAttemptedRes?.data?.attempt === 0) || Interview_Process.filter(item=>item===checkIsAttemptedRes?.data?.attemptedMediumType).length===0  ? (
          <AwsChatbot
            onSessionEnd={_onSessionEnd}
            siteData={siteData}
            disableButton={checkIsAttemptedRes?.data?.attempt>0?true:false}
            checkIsAttemptedRes={checkIsAttemptedRes}
          
          />
        ) : checkIsAttemptedRes?.data?.attempt !== 0 ? (
          <SuccessPath
            message={t("AWS_message")}
            setShow={successMessage}
          />
        ) : (
          <SuccessPath
            message={t("AWS_message_2")}
            setShow={successMessage}
          />
        ))}
      {Interview_Tabs.TALK_WITH_BOT === activeTab && (
        <>
          {!successMessage ? (
            <TalkWithBot
              disableButton={
                checkIsAttemptedRes?.data?.attempt > 0 ? true : false
              }
              onClickActionButton={onClickActionButton}
              type={Interview_Tabs.TALK_WITH_BOT}
              siteData={siteData}
              callEditScheduleApi={handleEditSchedule}
              {...props}
              checkIsAttemptedRes={checkIsAttemptedRes}
      
            />
          ) : (
            <SuccessPath
              message={t("TALK_TO_BOT_message")}
              setShow={successMessage}
            />
          )}
        </>
      )}
      {Interview_Tabs.QUESTIONNAIRE === activeTab && (
        <Interview
          disableButton={checkIsAttemptedRes?.data?.attempt > 0 ? true : false}
          type={Interview_Tabs.QUESTIONNAIRE}
          {...props}
          siteData={siteData}
          attemptInterView={() => attemptInterViewOnClick()}
          checkIsAttemptedRes={checkIsAttemptedRes}
        />
      )}

      {Interview_Tabs.ACCOUNTANT_CALL === activeTab && (
        <React.Fragment>
          {!successMessage ? (
            <TalkWithBot
              onClickActionButton={onClickActionButton}
              disableButton={
                checkIsAttemptedRes?.data?.attempt > 0 ? true : false
              }
              type={Interview_Tabs.ACCOUNTANT_CALL}
              siteData={siteData}
              setActiveTab={setActiveTab}
              callEditScheduleApi={handleEditSchedule}
              {...props}
            />
          ) : (
            <SuccessPath
              message={slotAvailable?t("Your offline scheduling request has been successfully submitted to the forensic accounting team. A confirmation email with further details will be sent to you shortly.") 
              :t("TALK_TO_BOT_1_1")+"#"+siteData.claimNumber+t("TALK_TO_BOT_1_2")+accountantMessage+t("TALK_TO_BOT_2")}
              // message="Your information will be sent to an accountant and they will be sending a request for information to you detailing all the documentation needed to evaluate your claim via an email or a phone call"
              setShow={successMessage}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );

  // } else {
  //   if (readyToRender === "In Progress") {
  //   } else {
  //     return <div className="container mx-auto">{readyToRender}</div>;
  //   }
  // }
};
export default StartInterviewForAdjuster;
