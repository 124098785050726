import axios from "axios";
import BASE_URL from "./ApiUrl";

const axiosInstanceChatbot = axios.create({
  baseURL:BASE_URL.chatBot,
  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosInstanceChatbot;



