import { createSlice } from "@reduxjs/toolkit";
import {
  nextQuestion,
  submitAnswer,
  startQuestions,
} from "../../api/chatbotApi";

interface chatbotState {
  loading: boolean;
  error: boolean;
  nextQuestionError: boolean;
  submitAnswerError: boolean;
  chatbotMessage: Array<any>;
  submitAnswerRes: any;
  startQuestionsRes: any;
  messages? :any
}
const initialState: chatbotState = {
  loading: false,
  error: false,
  nextQuestionError: false,
  submitAnswerError: false,
  chatbotMessage:[],
  submitAnswerRes: null,
  startQuestionsRes: null,
  messages:[]
};

const chatBotSlice = createSlice({
  name: "Chatbot",
  initialState,
  reducers: {
    resetChatBotData: (state) => {
      state.loading = false;
      state.error = false;
      state.nextQuestionError = false;
      state.submitAnswerError = false;
      state.chatbotMessage = [];
      state.submitAnswerRes = null;
      state.startQuestionsRes = null;

      // return initialState;
    },
    setMessagesValue:(state,action)=>{
      if(action.payload==null){
        state.chatbotMessage=[]
      }else{
      state.chatbotMessage=action.payload
      }
    },
    
    setUserInputMessage:(state,action)=>{
      state.chatbotMessage = [...state.chatbotMessage,action.payload];
    },
    updateChatBot:(state,action)=>{
      state.chatbotMessage = action.payload;
    }


  },
  extraReducers: (builder) => {
    builder
      .addCase(startQuestions.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.chatbotMessage = [];
      })
      .addCase(startQuestions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.chatbotMessage = [];
      })
      .addCase(startQuestions.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.chatbotMessage = [];
      })
      .addCase(nextQuestion.pending, (state) => {
        state.loading = true;
      })
      .addCase(nextQuestion.fulfilled, (state, action) => {
        state.loading = false;
        state.chatbotMessage = [...state.chatbotMessage,action.payload];
      })
      .addCase(nextQuestion.rejected, (state, action) => {
        state.loading = false;
        state.chatbotMessage = [...state.chatbotMessage,action.payload];
      })
      .addCase(submitAnswer.pending, (state, action) => {
        state.loading = true;
        // state.chatbotMessage = [...state.chatbotMessage,action.payload];
      })
      .addCase(submitAnswer.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload?.valid==false){
          const newErrorMsg={
            valid:false,
            question:action.payload.feedback,
            question_id:state.chatbotMessage[state.chatbotMessage.length-1]?.question_id
          }
        state.chatbotMessage = [...state.chatbotMessage,newErrorMsg];
        }
      })
      .addCase(submitAnswer.rejected, (state, action) => {
        console.log("action",action)
        state.loading = false;
        // state.chatbotMessage = [...state.chatbotMessage,action.payload];
        
      });

  },
});
export const { resetChatBotData,setMessagesValue,setUserInputMessage,updateChatBot} = chatBotSlice.actions;
export default chatBotSlice.reducer;
