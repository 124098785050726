import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
interface Props {
  onChange: (e: any) => void;
  value: any;
  minDateTime?: Date | undefined;
  maxDateTime?: Date | undefined;
  className?: string;
  error?: boolean;
  errorText?: string;
  showTime?: boolean;
  disabled?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  timezone?: string;
}

const timezones: Record<string, string> = {
  "Pacific Standard Time": "America/Los_Angeles",
  "Eastern Standard Time": "America/New_York",
  "Central Standard Time": "America/Chicago",
  "Mountain Standard Time": "America/Denver",
};

const DateTimePicker: React.FC<Props> = ({
  onChange,
  value,
  minDateTime,
  maxDateTime,
  className,
  error,
  errorText,
  onBlur,
  disabled,
  showTime,
  timezone
}) => {

  const [dateValue, setDateValue] = useState<any>("");

  useEffect(() => {
    setDateValue(
      (value && value.length > 3
        ? value.replace("T", " ").replace(":00.32120", "")
        : "") || value
    );
  }, [value]);

  const handleChange = (date: any) => {
    if (date) {
      const now = getCurrentTimeInTimezone(timezone);

      // If the selected date is today, set time to 15 minutes ahead
      if (now.toDateString() === date.toDateString()) {
        const nextAvailableTime = new Date(now.getTime() + 22 * 60 * 1000);
        date.setHours(nextAvailableTime.getHours(), nextAvailableTime.getMinutes(), 0, 0);
      }

      setDateValue(date);
      onChange(date);
    }
  };

  const getCurrentTimeInTimezone = (timezone?: string) => {
    if (!timezone) return new Date();

    const formatter = new Intl.DateTimeFormat("en-US", {
      timeZone: timezone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });

    // Parse the formatted date string into a Date object
    const parts = formatter
      .formatToParts()
      .reduce((acc: any, part) => ({ ...acc, [part.type]: part.value }), {});

    return new Date(
      `${parts.year}-${parts.month}-${parts.day}T${parts.hour}:${parts.minute}:${parts.second}`
    );
  };

  const disableTime = (time: any) => {
    const now = getCurrentTimeInTimezone(timezone);
    const selectedTime = new Date(time);

    // Check if the selected date is today
    const isToday = now.toDateString() === selectedTime.toDateString();

    // If the selected date is today, disable times after the current time
    if (isToday) {

      const nowPlusTenMinutes = new Date(now.getTime() + 10 * 60 * 1000);

      // Disable times before nowPlusTenMinutes
      return selectedTime >= nowPlusTenMinutes;
    }

    // Allow all times for future dates
    return true;
  };


  return (
    <div className="w-full">
      {showTime ?
        <DatePicker
          selected={dateValue}
          onChange={(date: any) => handleChange(date)} // Handle date and time change
          showTimeSelect
          dateFormat="yyyy-MM-dd h:mm aa" // Custom format for date and time
          timeIntervals={15} // 15-minute intervals
          placeholderText="Select date and time"
          disabled={disabled}
          minDate={
            minDateTime
              ? new Date(minDateTime)
              : undefined
          }
          maxDate={
            maxDateTime
              ? new Date(maxDateTime)
              : undefined
          }
          className={`${className
            ? className
            : "border-2 text-sm !w-96 p-2 outline-blue-600 h-9 rounded-lg bg-white"
            } ${error && "!border-2 !border-red-600"}`}
          filterTime={disableTime}
        /> :
        <DatePicker
          selected={dateValue}
          onChange={(date: any) => handleChange(date)} // Handle date and time change
          placeholderText="Select date"
          disabled={disabled}
          minDate={
            minDateTime
              ? new Date(minDateTime)
              : undefined
          }
          maxDate={
            maxDateTime
              ? new Date(maxDateTime)
              : undefined
          }
          className={`${className
            ? className
            : "border-2 text-sm !w-96 p-2 outline-blue-600 h-9 rounded-lg bg-white"
            } ${error && "!border-2 !border-red-600"}`}
        />}

      {/* <input
   
        min={minDateTime
          ? minDateTime.toISOString().slice(0, 16)  // Extracts "YYYY-MM-DDTHH:MM" from ISO string
          : undefined}
        max={maxDateTime
          ? maxDateTime.toISOString().slice(0, 16)  // Extracts "YYYY-MM-DDTHH:MM" from ISO string
          : undefined}
        className={`${className
            ? className
            : "border-2 text-sm w-full p-2 outline-blue-600 h-9 rounded-lg"
          } ${error && "!border-2 !border-red-600"}`}
        type="datetime-local"
        value={dateValue}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={onBlur}
             step={"900"}
       
      />
      {errorText && errorText.length && (
        <div className="text-sm mt-[-1] text-red-600">{errorText}</div>
      )} */}
    </div>
  );
};

export default DateTimePicker;