/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
/* eslint-disable jsx-a11y/alt-text */
import CustomButton from "../../components/CustomButton";
import { cx } from "class-variance-authority";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import time from "../../../src/assets/images/time.png";
import { Checkbox } from "@mui/material";
import { TIME } from "src/utils/constant";
import CalendarWithEvents from "./CalendarWithEvents";
import { RootState } from "src/store";
import { useAppSelector } from "src/store/hooks";
import InlineLoader from "src/components/loader/InlineLoader";
import {  convertESTToAnyTimezone, convertToEST } from "src/utils";
import moment from "moment";
import { useTranslation } from 'react-i18next';

interface TalkWithBotFormProps {
  formValue: any;
  timeZones: any[];
  disableButton: boolean;
  onDateTimeChange: (value: string) => void;
  onTimeZoneChange: (value: string) => void;
  onDateChange: (value: string) => void;
  onTimeChange: (value: string) => void;
  onCommentChange: (value: string) => void;
  onSlotAvailableChange: (value: any) => void;
  onSubmit: () => void;
  onClear: () => void;
  selectedDate: Date | null;
  events: any;
  onDateChangee: (date: any | any[]) => void;
  callDeleteSchedule: (id: any) => void;
  handleItemIdChange: (data: any, item?: any) => void;
  setActiveTab: () => void;
}

const TalkWithBotForm: React.FC<TalkWithBotFormProps> = ({
  formValue,
  timeZones,
  onDateTimeChange,
  onTimeZoneChange,
  onDateChange,
  onTimeChange,
  onSubmit,
  onClear,
  disableButton,
  onSlotAvailableChange,
  onCommentChange,
  selectedDate,
  events,
  onDateChangee,
  callDeleteSchedule,
  handleItemIdChange,
  setActiveTab,
}) => {
    const {t}= useTranslation();
  const [country, setCountry] = useState("");
  const [availableSlot, setAvailableSlot] = useState(true);
  const timeZoneOptions = timeZones.map((zone) => ({
    label: `${zone.name} (${zone.abbreviation}) - ${zone.utcOffset}`,
    value: zone.name,
    country: zone.value,
  }));
  const isDisabled = (date:any) => {
    const today = new Date();
    const day = date.getDay(); // Get the day of the week (0 is Sunday, 6 is Saturday)

    // Disable Saturday (6), Sunday (0), and past dates
    return day === 0 || day === 6 || date < today.setHours(0, 0, 0, 0) ;
  };
  const isDisabledall = (date:any) => {
    const today = new Date();
    const day = date.getDay(); // Get the day of the week (0 is Sunday, 6 is Saturday)

    // Disable Saturday (6), Sunday (0), and past dates
    return day === 0 || day === 6 || date < today.setHours(0, 0, 0, 0) || date >= today.setHours(0, 0, 0, 0) ;
  };
  const { interviewSlots, loadingSlot } = useAppSelector(
    (state: RootState) => state.interview
  );
  const [timeSlotList, setTimeSlotList] = useState<Array<any>>(TIME);

  useEffect(() => {
    if (country && formValue.date) {
      const newTimeList = TIME.map((element: any) => ({
        ...element,
        isAvailable: convertToEST(moment(formValue.date).format("YYYY-MM-DD") , element.time24, country)
          ?.isInBusinessHours,
      }));
      setTimeSlotList(newTimeList);
    }
  }, [formValue.timeZone, formValue.date, country]);
  const checkIsSlotAvailabe = (item: any) => {
    if (item.isAvailable) {
      if (formValue?.date) {
        const date = new Date(formValue.date);
        const status = date.getDay() === 6 || date.getDay() === 0;
        if (status) return !status;
      }
      const index = interviewSlots.findIndex((slots: any) =>
        slots.start.dateTime.includes(item.time24)
      );
      if (index >= 0) return false;
      else return true;
    } else return false;
  };
  useEffect(() => {
    if (formValue.timeZone) {
      const country = timeZones.find(
        (zone) => zone.name === formValue.timeZone
      )?.value;
      setCountry(country);
    }
  }, [formValue.timeZone]);
  return (
    <>
    {!formValue.slotAvailable&&
      <form  className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-2 py-4 px-4 w-full ">
        <div className="flex flex-col">
          <label className="text-[#000]-500 font-poppins text-sm font-medium mb-1 ">
            {t("Select Time Zone")}<span className="text-red-500">*</span>
          </label>
          <Select
            options={timeZoneOptions}
            className="basic-single disabled:cursor-not-allowed"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            name="timeZone"
            onChange={(selectedOption:any) => {
              onTimeZoneChange(selectedOption ? selectedOption.value : "");
           
            }}
            isDisabled={formValue.id === "" ? disableButton : false}
            value={
              timeZoneOptions.find(
                (option) => option.value === formValue.timeZone
              ) || null
            }
          />
          <label className="text-[#000]-500 font-poppins text-sm font-medium mt-2 ">
             {t("Select Date")}<span className="text-red-500">*</span>
          </label>
          {events?.length > 0 && formValue.id === "" ?
          <DayPicker
            mode="single"
            selected={formValue.date || new Date()}
            disabled={isDisabledall}
          />:<DayPicker
          mode="single"
          selected={formValue.date || new Date()}
          onSelect={(value: any) => onDateChange(value)}
          disabled={isDisabled}
        />}
        </div>
        { (
          <div className="flex flex-col">
            { (
              <div className="flex justify-between">
                <label className="text-[#000]-500 font-poppins text-sm font-medium">
                   {t("Select Time")}<span className="text-red-500">*</span>
                </label>
                {/* {!formValue.slotAvailable&&
                <div className="flex justify-between">
                <Switch color="indigo" onClick={()=>setAvailableSlot(!availableSlot)} defaultChecked  />
                 <div className="w-36 text-left ml-4">
                  {availableSlot?"Available Slots":"Unavailable Slots"}
                  </div>
                  </div>
} */}
              </div>
            )}
            { (
              <div className="bg-white !max-h-[390px] overflow-auto p-4">
                { loadingSlot ? (
                  <div className="flex flex-col h-[374px]">
                    <div className="h-16 mt-10 ">
                      <div className="w-6 h-6 text-center m-auto mb-2">
                        <InlineLoader />
                      </div>
                      <div className="text-sm mt-4 text-center">
                       {t("Checking the available slot on selected date. Please wait...")}</div>
                    </div>
                  </div>
                ) :
                timeSlotList.filter((item)=>item.isAvailable===true).length===0 ||(events?.length > 0 && formValue.id === "") ?
                <div className="h-[370px]">
                  
                <span className="text-black text-sm font-medium"> {t("No Slots Available")}</span>
         
                </div>
                :
                   timeSlotList.map((item: any, index: number) => (
                  <div
                    onClick={() => {

                      if (checkIsSlotAvailabe(item)) {
                        onTimeChange(item.time24);
                      } else if (formValue.slotAvailable) {
                        onTimeChange(item.time24);
                      }
                    }}
                  >
                    {availableSlot
                      ? checkIsSlotAvailabe(item) && (
                          <div
                            key={index}
                            className={`  h-12 flex rounded-md cursor-pointer mb-2 ${formValue.time === item.time24 ? "border-[#1F7FBB] border-2 text-[#1F7FBB]" : "border-grey border-2 text-[#3d3d3d] font-poppins"} `}
                          >
                            <img src={time} className={`ml-2 w-8 h-8 mt-1`} />{" "}
                            <span className={`px-4 py-2`}>{item.label}</span>
                            {/* <CheckOutlined className="text-green-500 !text-[20px] mt-3 text-right" /> */}
                          </div>
                        )
                      : !checkIsSlotAvailabe(item) && (
                          <div
                            key={index}
                            className={`  h-12 flex rounded-md cursor-pointer mb-2 ${formValue.time === item.time24 ? "border-[#1F7FBB] border-2 text-[#1F7FBB]" : "border-grey border-2 text-[#3d3d3d] font-poppins"}`}
                          >
                            <img src={time} className="ml-2 w-8 h-8 mt-1" />{" "}
                            <span className="px-4 py-2">{item.label}</span>
                          </div>
                        )}
                  </div>
                ))}
              </div>
            )
            
            }
          </div>
        )}
        {events?.length > 0 ? (
          <div className="flex flex-col">
            <label className="text-[#000]-500 font-poppins text-sm font-medium mb-1 ">
               {t("Scheduled Interview")}
            </label>
            <div className="bg-white h-[385px] p-2">
              <div className="grid">
                <span className="text-black text-sm font-semibold"> {t("Date")}:</span>
                <span className="text-[#A7A7A7] text-sm">
                 
                  {moment(convertESTToAnyTimezone(events[0]?.interviewStartTime,  timeZones.find(item=>item?.abbreviation===events[0]?.userTimeZone)?.value )).format("dddd, MMMM DD, YYYY")}
                 
                </span>
              </div>
              <div className="grid mt-2">
                <span className="text-black text-sm font-semibold">{t("Time")}:</span>
                <span className="text-[#A7A7A7] text-sm"> 
                   {moment(convertESTToAnyTimezone(events[0]?.interviewStartTime,
                    timeZones.find(item=>item?.abbreviation===events[0]?.userTimeZone)?.value 
                  )).format("hh:mm:ss a")}</span>
              </div>
              <div className="grid mt-2">
                <span className="text-black text-sm font-semibold">
                   {t("TimeZone")}:
                </span>
                <span className="text-[#A7A7A7] text-sm">
                  {timeZones.find(item=>item?.abbreviation===events[0]?.userTimeZone)?.label }

                </span>
              </div>

              <div className="grid mt-2">
                <span className="text-black text-sm font-semibold">
                   {t("Status")}:
                </span>
                <span className="text-[#A7A7A7] text-sm">
                  
                 {events?.[0]?.interviewStatus}
                </span>
              </div>
              {events?.length > 0 && (
                <CalendarWithEvents
                  selectedDate={selectedDate}
                  events={events}
                  handleItemIdChange={handleItemIdChange}
                  onDateChangee={onDateChangee}
                  callDeleteSchedule={callDeleteSchedule}
                  setActiveTab={setActiveTab}
                  timeZones={timeZones}
                />
              )}
            </div>
          </div>
        ) : null}
      </form>
}
      <p className="flex pl-4 text-sm font-semibold pt-3 !w-[45vw]">
        <span className="!text-[black]">
           {t("If the proposed time slots don't fit your schedule, please check the  box to indicate your preferred time.")}
          <Checkbox
            className="h-8 w-8 border-2 border-black disabled:cursor-not-allowed"
            disabled={events?.length > 0 && formValue.id === ""}
            onChange={() => {
              onCommentChange("")
              onSlotAvailableChange(!formValue.slotAvailable);
            }}
          />
        </span>
      </p>
      <div className=" mt-2 items-center">
        {formValue.slotAvailable && (
          <form className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 gap-2 py-4 px-4 w-full ">
             <div className="flex flex-col">
              <label className="text-[#000]-500 font-poppins text-sm font-medium mt-2 mb-2 ">
                 {t("Offline Scheduling Request")}
                <span className="text-red-500">*</span>
              </label>
              <div>
                <textarea
                  id={"message"}
                  className="block p-2.5 text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 !w-[45vw] disabled:cursor-not-allowed"
                  // placeholder={t("Write your thoughts here...")}
                  placeholder="Enter your preferred Date, Time & Time-zone in the format of MM/DD/YYYY, 12-Hour time, AM/PM, Time-zone."
                  value={formValue?.comments}
                  rows={4}
                  disabled={events?.length > 0 && formValue.id === ""}
                  onChange={(e) => onCommentChange(e.target.value)}
                ></textarea>
                
              </div>
            </div>
          </form>
        )}
      </div>

      <div className="flex lg:flex-row md:flex-row sm:flex-row gap-2 py-4 px-4 justify-center">
        <CustomButton
          className={cx(
            "bg-[#1F7FBB]",
            "border-[#1F7FBB]",
            "justify-center",
            "shadow-md shadow-[#1F7FBB]",
            "w-[150px]"
          )}
          disabled={formValue.id === "" ? disableButton : false}
          label= {t("Submit")}
          onClick={onSubmit}
        />
{!formValue.slotAvailable&&
        <CustomButton
          className="w-[150px] bg-[#fff] shadow-md shadow-[#fff] sm:w-auto rounded-lg pl-7 pr-7 pt-4 pb-4 bg-black !text-white border-none font-semibold hover:!text-white transition-transform transform active:scale-95 shadow-xl hover:shadow-xl"
          label={t("Reset")}
          onClick={onClear}
        />
}
      </div>
    </>
  );
};

export default TalkWithBotForm;
