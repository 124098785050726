/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */

import calleli from "../../../assets/images/calleli.png";
import sign from "../../../assets/images/zigzag.png";
import { useCallback, useEffect } from "react";
import {
  Interview_Mode,
  Interview_Process,
  Interview_Tabs,
} from "../../../utils/constant";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
interface Props {
  setActiveTab: (e?: string) => void;
  activeTab: any;
  checkIsAttemptedRes?: any;
  tabs:any
}
const ScheduleTabs = ({
  activeTab,
  setActiveTab,
  checkIsAttemptedRes,
  tabs
}: Props) => {
  const handleTab = useCallback((step: string) => {
    setActiveTab(step);
  }, []);
  const { t } = useTranslation();
  // useEffect(() => {
  //   if (checkIsAttemptedRes?.data?.attempt > 0) {
  //     const { attemptedMediumType } = checkIsAttemptedRes?.data;
  //     if (attemptedMediumType === "TALK_TO_ACCOUNTANT") {
  //       handleTab(Interview_Tabs.ACCOUNTANT_CALL);
  //     } else if (attemptedMediumType === "FILL_OUT_QUESTIONNAIRE") {
  //       handleTab(Interview_Tabs.QUESTIONNAIRE);
  //     } else if (attemptedMediumType === "TALK_TO_BOT") {
  //       handleTab(Interview_Tabs.TALK_WITH_BOT);
  //     } else if (attemptedMediumType === "CHAT_WITH_BOT") {
  //       handleTab(Interview_Tabs.CHAT_WITH_BOT);
  //     }
  //   }
  // }, [checkIsAttemptedRes, handleTab]);
const clickOnDisable=()=>{
  if(Interview_Process.filter(item=>item===checkIsAttemptedRes?.data?.attemptedMediumType).length>0 ){
   let msg =t("RETAKE_MESSAGE")
    toast.warn(msg)
  }
}

  return (
    <div className="container flex flex-col w-full ">
      <div className="flex ">
        <img src={calleli} className="bg-eli" />
        <div className=" font-[400] text-[15px] leading-[26px] md:text-[18px] md:leading-[31px] text-[#656F80] mt-2  ">
 
 <span className="font-semibold !text-black-900 whitespace-nowrap z-50">{t("Meet Eli")}</span> 
  {t("Eli is a friendly and efficient chatbot designed to help you report information about your business interruption and extra expense losses. Through a series of guided questions, Eli ensures that all necessary details are collected accurately, making it easier for users to share the information needed for accurate and swift claim resolution.")}
</div>
</div>
{/* <img className="h-4 md:h-4 w-64 relative left-[60%] mt-2" src={sign} /> */}
{checkIsAttemptedRes?.data?.attempt>0 && 
<div className="mt-2 border-2 border-[#0774D9] bg-[#d8ecff] px-4 py-2 rounded-lg">
       <p className="text-[#f50404] pl-5 font-semibold text-sm">
             {t("Note:")}{" "}
             <span className="">
             {checkIsAttemptedRes?.data?.attemptedMediumType=="TALK_TO_ACCOUNTANT"?
            t("You have already scheduled the interview")
             :
             t("You have already attempted the interview")
             }
              {" "} {t("with")}  {tabs.find((item:any)=>item.selection===Interview_Mode[checkIsAttemptedRes?.data?.attemptedMediumType])?.title}</span>
           </p>
       </div>
        } 
<span className="text-lg text-[#000] font-semibold mt-4">{t("Please choose your preferred interview mode")} :</span>
<div className="grid sm:grid-cols-1  md:grid-cols-2 lg:grid-cols-2 xs-grid-cols-1 gap-3 mt-2">
      {tabs.map((item:any,key:number)=>
                <div key={key} className="z-[999999] rounded overflow-hidden shadow-lg border-[3px] border-[#0774D9] rounded-xl sm:h-[170px] md:h-[170px] lg:h-[170px] xs:h-[170px] p-2" onClick={()=>clickOnDisable() }>
                    <div className="flex justify-between">
                      <div className="flex">
                    <img src={item.icon} width={item.selection=="Call Eli"?"40px":"60px"}/>
                    <div className="block">
                    <p className={`font-semibold text-base whitespace-nowrap ${item.selection=="Call Eli"?"ml-2":""}`}>{item.title}</p>
                    <div className="line rounded-xl"></div>
                    </div>
                    </div>
                    {/* <div>
                     {item.selection=="Call Eli" &&<span className="font-semibold text-base comming">{t("Coming Soon...")}</span>}
                    </div> */}
                  </div>
                  <div className="text-base text-justify mt-2 leading-5 mt-2 h-10">
                    {item.description}
                  </div>
                  
                  <div className="justify-items-center pt-4 pb-2" >
                  <button
                  disabled={Interview_Process.filter(item=>item===checkIsAttemptedRes?.data?.attemptedMediumType).length>0 }
                  className="z-[999999] inline-block text-white rounded-full bg-[#0774D9] flex justify-center gap-5 items-center cursor-pointer font-[500] text-[14px] leading-[27px] pl-24 pr-24 h-[40px] whitespace-nowrap w-[20%] text-center disabled:bg-[#9a9ea1] disabled:cursor-not-allowed"  
                  onClick={()=>handleTab(item.selection)}><span>{item.buttonText}
                  </span></button>
               
                  </div>
               </div>)}
      </div>
    </div>
  );
};
export default ScheduleTabs;
