import { LoadScript } from "@react-google-maps/api";
import DefinePath from "../Features/Router";
import { config } from "src/config";

function App() {
  return (
    <LoadScript googleMapsApiKey={config.MAP_KEY} libraries={["places"]}>
      <DefinePath />
    </LoadScript>
  );
}

export default App;
