import React, { useEffect, useMemo } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./CalendarWithEvents.css";
import CardWithMenu from "./CardWithMenu";
import moment from "moment";
import Index from "../InterviewForAdjuster/interview";

interface CalendarWithEventsProps {
    selectedDate: Date | null;
    events: any;
    onDateChangee: (date: any | any[]) => void;
    callDeleteSchedule:(id:any)=>void
    handleItemIdChange: (data: any,item?:any ) => void;
    setActiveTab:()=>void;
    timeZones:any

}

const formatDate = (date: Date | null) => {
    if (!date) return "";
    return date.toLocaleDateString("en-GB", {
        weekday: "short",
        day: "2-digit",
        month: "short",
        year: "numeric"
    });
};

const CalendarWithEvents: React.FC<CalendarWithEventsProps> = ({
    selectedDate,
    events,
    callDeleteSchedule,
    handleItemIdChange,
    setActiveTab,
    timeZones
}) => {
  return (<ul>
        {events?.length>0?
        events?.map((item:any,index:number)=>(
                    <CardWithMenu item={item} selectedDate={selectedDate} key={index}
                    handleItemIdChange={handleItemIdChange}
                    callDeleteSchedule={callDeleteSchedule}
                    setActiveTab={setActiveTab}
                    timeZones={timeZones}
              
                    />
              
            ))
            
        
        :
        (
            <p className="text-sm text-gray-500"></p>
        )
    }
    </ul>
      
    )
};

export default CalendarWithEvents;
