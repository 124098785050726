import moment from 'moment';
import React, { useState, useRef } from 'react';
import { DialogCustomAnimation } from 'src/components/dialogForChangeMode';
import { convertESTToAnyTimezone } from 'src/utils';
import { useTranslation } from 'react-i18next';
interface props{
  item:any
  selectedDate:any
  callDeleteSchedule:(id:any)=>void
  handleItemIdChange: (date: any,item?:any ) => void;
  setActiveTab:()=>void;
  timeZones:any
}
const CardWithMenu = ({item,callDeleteSchedule,handleItemIdChange,setActiveTab,timeZones}:props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
      const {t}= useTranslation();
  const [open,setOpen]=useState(false)
  const menuRef = useRef(null);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleEdit = () => {
    handleItemIdChange({id:item?.id,
      dateTime:new Date(moment(convertESTToAnyTimezone(item?.scheduleDate,
                          timeZones.find((itemm:any)=>itemm?.abbreviation===item?.userTimeZone)?.value 
                        )).format()),timeZone:item?.timezone,
      date:item?.date,time:item?.time,
      timeZonesValue:timeZones.find((itemm:any)=>itemm.abbreviation===item?.userTimeZone).name
    },item)
    setIsMenuOpen(false); // Close menu after clicking
  };

  function handleDelete() {
    callDeleteSchedule(item?.id)
    setIsMenuOpen(false); // Close menu after clicking
  };

  return (
    <div className="relative flex ">


      <div className="ml-auto relative" ref={menuRef}>

      <button onClick={toggleMenu} type="button"
        className="  get__started__btn basic__hover  text-white flex justify-center gap-5 items-center whitespace-nowrap !cursor-pointer font-[500] text-[14px] leading-[18px] py-4 px-4 rounded-md !h-[28px]">
       {t("Update Interview")} </button>
        {/* Dropdown Menu */}
        {isMenuOpen && (
          <div className="absolute right-0 mt-2 w-64 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
            <button
             type="button"
              onClick={handleEdit}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              {t("Change Interview Schedule")}
            </button>
            <button
              type="button"
              onClick={()=>setOpen(true)}
              className="block w-full text-left px-4 py-2 text-sm text-gray-600 hover:bg-gray-100"
            >
              {t("Change Interview Mode")}
            </button>
          </div>
        )}
      </div>
      <DialogCustomAnimation setActiveTab={setActiveTab} open={open} handleOpen={()=>setOpen(!open)} title="Select Interview Mode"/>
    </div>
  );
};

export default CardWithMenu;
