import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

interface FormState {
  id?: any;
  dateTime: string;
  timeZone: string;
  date?: any;
  time?: string;
  userTimeZone?: string
  comments?: string;
  slotAvailable?: boolean;
}

const initialState: FormState = {
  id: "",
  dateTime: "",
  timeZone: "",
  time:"",
  userTimeZone:"",
  comments:"",
  slotAvailable:false,
  date:null
};

export const botSlice = createSlice({
  name: "talkBot",
  initialState,
  reducers: {
    updateDateTime: (state, action: PayloadAction<string>) => {
      state.dateTime = action.payload;
    },
    updateTimeZone: (state, action: PayloadAction<string>) => {
      state.timeZone = action.payload;
    },
    updateTime: (state, action: PayloadAction<string>) => {
      state.time = action.payload;
    },
    updateDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    updateComments: (state, action: PayloadAction<string>) => {
      state.comments = action.payload;
    },
    updateSlotAvailable: (state, action: PayloadAction<any>) => {
      state.slotAvailable = action.payload;
    },

    updateId: (state, action: any) => {
      console.log("action", action);
      state.id = action?.payload?.id;
      state.dateTime = action?.payload?.dateTime;
      state.date = new Date(moment(action?.payload?.dateTime).format("MM/DD/YYYY"))
      state.time =  moment(action?.payload?.dateTime).format("h:mm:ss a")
      state.timeZone =  action?.payload?.timeZonesValue;
      state.comments = action?.payload?.comments;
      state.slotAvailable = action?.payload?.slotAvailable;
      // return newState;
    },
    clearSelection: () => initialState,
  },
});

export const {
  updateDateTime,
  updateTimeZone,
  updateComments,
  updateSlotAvailable,
  clearSelection,
  updateId,
  updateTime,
  updateDate,
} = botSlice.actions;

export default botSlice.reducer;
