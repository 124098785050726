
import axiosInstance from './axiosInstance';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const scheduleInterview = createAsyncThunk("scheduleInterview", async (payload:InterViewSchedule, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/interview/schedule',payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });


  export const getInterviewScheduleListByClaimNumber = createAsyncThunk("getInterviewScheduleListByClaimNumber", async (payload:any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/interview/getInterviewScheduleListByClaimNumber',payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });


  export const getScheduledEventList = createAsyncThunk("getScheduledEventList", async (payload:any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('interview/getScheduledEventList',payload);
      let data:Array<any>= response.data?.data||[];
      if(data?.length>0){
        const date=payload.startDateTime?.split("T")[0]
       data= data.filter((el:any)=>el.start.dateTime.includes(date))
      }
      return data||[]
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });
  

  export const attemptInterview = createAsyncThunk("attemptInterview", async (payload:attemptInterview, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/interview/attempt',payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });

  export const getScheduleList = createAsyncThunk("getScheduleList", async (payload:scheduleList, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('interview/getInterviewScheduleListByClaimNumber',payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });
  export const deleteSchedule = createAsyncThunk("deleteSchedule", async (payload:any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/interview/delete?claimsId=${payload.claimsId}&interviewId=${payload.interviewId}`);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });
  export const editSchedule = createAsyncThunk("editSchedule", async (payload:any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/interview/editScheduleInterview/${payload.id}`,payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });

  export const checkIsAttempted = createAsyncThunk("checkIsAttempted", async (payload:any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/interview/checkIsAttempted/${payload.claimId}`);
      return response.data;

    } catch (error:any) {
        
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });
