export const config = {
  auth2BaseUrl: "https://ampcusforensicsdev.us.auth0.com",
  clientId: "7YcZR1Y7LMpZlv5IEexKp6UGwKXdgYMX",
  secretKey: "ajsX5nWiDeIkX1haFT5mJP-w5EhN6-1mFxrWWBDK_RcLc-jIE_Xw_8O0O-S2pixo",

  ACS_CONNECTION_STRING:
    "endpoint=https://talkwithbot.unitedstates.communication.azure.com/;accesskey=1FVKiEOU7TVe8aF6tGUIsh7wFIbze1281p4K1I4d81GPvs5s7HfkJQQJ99AKACULyCpoKL4RAAAAAZCSaFGr",
  AZURE_SPEECH_KEY:
    "5JOBEAG3Q6kwXnpSWWZ9dcyR6nXNhH7N2QcL9p7UWq8YZWLukdkUJQQJ99AKACYeBjFXJ3w3AAAYACOGog90",
  AZURE_REGION: "eastus",
  MAP_KEY:"AIzaSyAI10DY-Vuc-BYb4McbIm2J4wV-ywoDDeE"
};
