import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Language detection
import Backend from 'i18next-http-backend'; // Backend for loading translations

// Initialize i18next with the plugins
i18n
  .use(Backend) // For loading translations
  .use(LanguageDetector) // For detecting language
  .use(initReactI18next) // Initialize react-i18next
  .init({
    fallbackLng: 'en', // Default language if the user's language is not available
    debug: true, // Enable for development to see logs
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // Path to load translation files
    },
  });

export default i18n;