import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./axiosInstance";
import axiosInstanceChatbot from "./axiosInstanceChatbot";
import axiosInstanceFileUpload from "./axiosFileupload";
import axiosInstanceQues from "./axiosInstanceQues";

export const signup = createAsyncThunk("signUp", async (payload:User, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/auth/signUp',payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });

  export const login = createAsyncThunk("login", async (payload:User, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/auth/login',payload);
      
      const data=response?.data
      if(data?.user?.id && data?.token){
         axiosInstance.defaults.headers['Authorization'] = `Bearer ${data?.token}`
        //  axiosInstanceChatbot.defaults.headers['Authorization'] = `Bearer ${data?.token}`
        //  axiosInstanceFileUpload.defaults.headers['Authorization'] = `Bearer ${data?.token}`
        //  axiosInstanceQues.defaults.headers['Authorization'] = `Bearer ${data?.token}`
        localStorage.setItem("UserInfo",JSON.stringify(data)) 
      }
      return response.data;
    } catch (error:any) {
        
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response?.data?.message || error.message);
    }
  });

  export const changePassword = createAsyncThunk("changePassword", async (payload:User, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/auth/change-password',payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });

  export const getUserById = createAsyncThunk("getUserById", async (userId:any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/auth/${userId}`);
      return response.data;
    } catch (error:any) {
        
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });
  export const getUserList = createAsyncThunk("getUserList", async (userId:any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/auth/getUserList`);
      return response.data;
    } catch (error:any) {
        
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });

  export const addNewUser = createAsyncThunk("add-user", async (payload:any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/auth/signup`,payload);
      return response.data;
    } catch (error:any) {
        
      console.log("Error in signup API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });
  
  export const deleteUser = createAsyncThunk("deleteUSer", async (payload:any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/auth/deleteUser/${payload}`);
      return response.data;
    } catch (error:any) {
      console.log("Error in signup API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });

  export const sendOtp = createAsyncThunk("sendOtp", async (payload:User, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/auth/sendOtp',payload);
      return response.data;
    } catch (error:any) {
      console.log("Error in API call:", error);
      return rejectWithValue(error?.response.data.message || error.message);
    }
  });


 