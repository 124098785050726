import { useAppDispatch, useAppSelector } from "src/store/hooks";
import FormInput from "../../../components/FormInput";
import Claim from "../Claim";
import CustomButton from "src/components/CustomButton";
import { RootState } from "src/store";
import { useState } from "react";
import { setClientInfo } from "src/store/slices/claimSlice";
import { updateClientById } from "src/api/clientApi";
import { toast } from "react-toastify";
import Loader from "src/components/loader";
import { emailValidation } from "src/utils";
import { useTranslation } from 'react-i18next';
import CountrySelect from 'src/components/CountrySelect';
const ClientInformation: React.FC<{ client: any | null }> = ({ client }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isToggelView, setToggleEditView] = useState(false);
  const { clientInfo, claimDetails ,} = useAppSelector(
    (state: RootState) => state.claim
  );

  const { loading} = useAppSelector(
    (state: RootState) => state.client
  );
  const {
    clientCompany,
    email,
    firstName,
    lastName,
    middleName,
    phoneNumber,
    clientTitle,
  } = clientInfo;

  const getClientFormData = () => {
    const list = [
      {
        label: t("Client ID"),
        value: claimDetails?.clientDetails?.id,
        disabled: true,
        required: true 
      },
      { label: t("First Name"), value: firstName, key: "firstName",required: true },
      { label: t("Middle Name"), value: middleName, key: "middleName",required:false },
      { label: t("Last Name"), value: lastName, key: "lastName",required: true },
      { label: t("Client Job Title"), value: clientTitle, key: "clientTitle", required: false },
      { label: t("Client Company"), value: clientCompany, key: "clientCompany",required:false },
      { label: t("Client Phone Number"), value: phoneNumber, key: "phoneNumber",required: true },
      { label: t("Client Email Address"), value: email, key: "email",required: true },
    ];
    return list;
  };
  const handleInputChange = (event: any, key: any) => {
  
    const value = event.target.value;
    const reg=/^[0-9\b]+$/
   if(key ==="phoneNumber"){
    if(reg.test(value)||value==="")
        dispatch(setClientInfo({ ...clientInfo, [key]: value }));
   }else{
    dispatch(setClientInfo({ ...clientInfo, [key]: value }));
   }


  };

  const onReset = () => {};
  const onUpdate = () => {
    const {
      clientCompany,
      email,
      firstName,
      lastName,
      middleName,
      phoneNumber,
      clientTitle,
    } = clientInfo;

    const client = claimDetails?.clientDetails;
let errorFiled=""
    if(firstName?.trim()?.length===0){
        errorFiled += " first name,"
    } if(lastName?.trim()?.length===0){
        errorFiled += " last name,"
    } if(email?.trim()?.length===0){
        errorFiled += " valid email,"
    }
      if(phoneNumber?.trim()?.length===0){
        errorFiled += " valid phone number"
     }
if(errorFiled){
    toast.error("Please enter "+errorFiled) 
    return
}
    const payload = {
      clientId: client?.id,
      FirstName: firstName,
      MiddleName: middleName,
      LastName: lastName,
      PreferredName: client?.preferredName,
      Salutation: clientTitle,
      Suffix: clientCompany,
    //   UserDefinedIdentifier: client?.userDefinedIdentifier,
      BusinessCards: [
        {
          IsPrimaryCard: true,
          EmailAddresses: [email],
          RoleOrTitle: client?.RoleOrTitle, // "ROLE_USER",
          PhoneNumbers: [
            {
              Number: phoneNumber,
              CountryCode: "AU",
              Label: "Work",
            },
          ],
        },
      ],
    };
   

    dispatch(updateClientById(payload)).then((res: any) => {
        if (res?.payload?.statusCode === 200) {
          toast(res?.payload?.message);
          setToggleEditView(!isToggelView)
        }
        if (res?.payload?.status === 500) {
          toast.error(res?.payload?.message);
        }
      });
  };
  const handlePhoneNumber=(value:any)=>{
    dispatch(setClientInfo({ ...clientInfo, phoneNumber: value }));
  }
  return (
    
    
    <>
  { loading &&<Loader />}
      <div className="flex  justify-between items-center mb-4  ">
        <span className="font-bold text-[#045373]">Client Information</span>

        {!isToggelView ? (
          <img
            src={"/images/claimEdit.png"}
            alt="Edit Claim"
            className="w-10 h-6 pr-5 cursor-pointer"
            onClick={() => setToggleEditView(!isToggelView)}
          />
        ) : (
          <img
            src={"/images/eye.png"}
            alt="View Claim"
            className="w-10 h-6 pr-5 cursor-pointer"
            onClick={() => setToggleEditView(!isToggelView)}
          />
        )}
      </div>

      {getClientFormData().map(({ label, value, disabled, key,required }) => (
        <div key={label} className="flex flex-col gap-1">
          
         
          {isToggelView ? (
            <>
            {key === "phoneNumber" ? (
                <CountrySelect
                  name={label}
                  label={label}
                  required={required}
                  value={clientInfo?.phoneNumber || ""}
                  // className="border-2 border-[#CECECE]"
                  disabled={disabled}

                  onChange={handlePhoneNumber}
                />
              ) :
              (
              <>
            <FormInput
              disabled={disabled}
              name={label}
              label={label}
              required={required}
              type="text"
              className="border-2 border-[#b0bec5] border-t-[#b0bec5] bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10 focus:border-transparent
                          h-[34px]
                          "
              value={value}
              onChange={(event) => handleInputChange(event, key)}
            />
            {key=="email"&& !emailValidation(clientInfo?.["email"]) && <span className="text-red-700 text-xs"> Enter valid email </span>}
            
            </>
              )
              }
            
            </>
          ) : (
            <>
             <span className="font-medium text-sm ">{label}</span>
            <span className="font-medium whitespace-nowrap text-sm text-gray-700 mb-3 !select-text">{value}</span>
            </>
          )}
        </div>
      ))}
      <div className="flex flex-col sm:flex-row gap-3 mt-4 justify-between items-center">
        {isToggelView && (
          <div className="flex flex-col sm:flex-row gap-3">
            <CustomButton
              className="w-full sm:w-auto rounded-lg pl-7 pr-7 pt-4 pb-4 !text-white  bg-black border-none font-semibold hover:!text-white transition-transform transform active:scale-95 shadow-lg hover:shadow-xl"
              label={t("Reset")}
              onClick={onReset}
            />
            <CustomButton
              className="w-full sm:w-auto bg-[#1f7fbb] rounded-lg pl-7 pr-7 pt-4 pb-4 !text-white border-none font-semibold transition-transform transform active:scale-95 shadow-lg hover:shadow-xl"
              label={t("Update")}
              onClick={onUpdate}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ClientInformation;
