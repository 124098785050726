/* eslint-disable jsx-a11y/alt-text */
import React  from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {useLocation, useNavigate } from "react-router-dom";
import Eyeicon from "../../../assets/images/Eye_icon.png";
import { useTranslation } from 'react-i18next';
const OCRDocuments: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const claim = location.state?.claim;
      const { t } = useTranslation();
    const renderBackButton = () => (
        <div className="flex gap-2 items-center cursor-pointer text-lg sm:text-xl">
          <span
            className="font-semibold"
            onClick={() => navigate("/claim-details/claim-documents", { state: { claim } })}
          >
          <KeyboardBackspaceIcon fontSize="large" />
            {t("Back")}
          </span>
        </div>
      );

    const claimInfo =()=>(
        <div className="flex h-auto mt-4 rounded-xl shadow-md bg-white border-2 w-full border-blue-500 justify-between items-center">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
                 <div className="whitespace-nowrap text-sm font-medium px-4 py-2">
                    {t("Claim Number")} : &nbsp;
                    <span className="text-[#1F7FBB] font-semibold">{"#4324234"}</span>
                 </div>
                 <div className=" whitespace-nowrap text-sm font-medium px-4 py-2">
                    {t("Insured Business Name")} : &nbsp;
                    <span className="text-[#1F7FBB] font-semibold">{"test buisness"}</span>
                 </div>
                 <div className=" whitespace-nowrap text-sm font-medium px-4 py-2">
                    {t("Document Name")} : &nbsp;
                    <span className="text-[#1F7FBB] font-semibold">{"Name of the OCR Result Documents"}</span>
                 </div>
                </div>
        </div>

    );
    const renderTableHeaders = (headers: string[]) => (
        <thead className="bg-[#cfdce4]">
          <tr>
            {headers.map((header, idx) => (
              <th
                key={idx}
                className="px-3 py-3 font-semibold text-black text-sm  tracking-wider border border-black"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
      );
      const renderBody = () => {
        return (
          <tbody className="bg-white">
              <tr  className="hover:bg-gray-100">
                <td className="px-3 py-3 font-semibold text-sm text-black border border-black">
                  cxv
                </td>
                <td className="px-3 py-3 text-sm text-[#575555] border border-black">
                     sdfdsfds
                </td>
                <td className="px-3 py-3 text-sm text-[#575555] m-auto border border-black">
                <img src={Eyeicon}  width={"40px"}/>
                </td>
              </tr>
          </tbody>
        );
      };
return <div className="relative p-4 sm:p-1">
      {renderBackButton()}
      {claimInfo()}
      <table className="min-w-full border border-gray-300 text-center mt-4">
      {renderTableHeaders([
                    "Column 1",
                    "Colum 2",
                    "Action"
       ])} 
       {renderBody()}
                </table>
</div>
}
export default OCRDocuments