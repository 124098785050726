/* eslint-disable jsx-a11y/alt-text */
import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import DeleteIcon from "../../assets/images/Delete.png";
import { Tooltip } from "@material-tailwind/react";

interface PropsData {
  t: (key: string) => string;
  deleteUserFunc:(user:any)=>void
}

const columnDefs = ({deleteUserFunc, t }: PropsData): GridColDef<any>[] => [
  {
    field: "id",
    headerName: t("Id"),
    width: 100,
    sortable: true,
    renderCell: (params) => (
      <div>
        <span className="cursor-pointer">{params.value}</span>
      </div>
    ),
  },
  {
    field: "firstName",
    headerName: t("First Name"),
    width: 160,
    sortable: true,
    renderCell: (params) => (
      <div>
        <span className="">{params.value}</span>
      </div>
    ),
  },
  {
    field: "lastName",
    headerName: t("Last Name"),
    width: 150,
    sortable: true,
    renderCell: (params: any) => (
      <div>
        <span className=""> {params.value}</span>
      </div>
    ),
  },

  {
    field: "email",
    headerName: t("Email"),
    width: 200,
    sortable: true,
  },

  {
    field: "mobile",
    headerName: t("Mobile"),
    width: 180,
    sortable: true,
  },

  {
    field: "roles",
    headerName: t("Role"),
    width: 150,
    sortable: true,
    renderCell: (params: any) => (
      <div>
        <span className="">
          {params.row?.roles?.[0] === "ROLE_ADMIN" ? "Admin" : "User"}
        </span>
      </div>
    ),
  },
  {
    field: "createdDate",
    headerName: "Created Date",
    width: 170,
    sortable: false,
    renderCell: (params: any) => (
      <span className="">
        {params.value ? moment(params.value).format("YYYY-MM-DD hh:mm A") : "-"}
      </span>
    ),
  },
  {
    field: "Action",
    headerName: t("Action"),
    width: 150,
    sortable: false,
    renderCell: (params: any) => (

      <Tooltip
        content={"Delete User"}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0, y: 25 },
        }}
        placement="top"
      >
        <img src={DeleteIcon} width={"20px"} onClick={() => deleteUserFunc(params.row)} className="mt-3 cursor-pointer" />
      </Tooltip>
    ),
  },
];

export default columnDefs;
