import PolicyGrid from "../modules/Documents/Policy Docs/PolicyGrid";
import PicturesGrid from "../modules/Documents/Pictures Docs/PicturesGrid";
import PayrollGrid from "../modules/Documents/Payroll Docs/PayrollGrid";
import InvoicesGrid from "../modules/Documents/Invoices Docs/InvoicesGrid";
import FinancialStatementGrid from "../modules/Documents/Financial Statements Docs/FinancialStatementGrid";
import {
  coverage,
  DemographicData,
  FinancialStatement,
  LossInformation,
} from "./mockData";
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface progresBarTabsProps {
  step: number;
  name: string;
  percentage: number;
  data?: any;
}
export enum Interview_Tabs {
  
  TALK_WITH_BOT = "Call Eli",
  CHAT_WITH_BOT = "Chat with Eli",
  ACCOUNTANT_CALL = "Talk to Our Accountant",
  QUESTIONNAIRE = "Claim intake",
}
export const Interview_Mode:any ={
  
  TALK_WITH_BOT : "Call Eli",
  CHAT_WITH_BOT : "Chat with Eli",
  TALK_TO_ACCOUNTANT : "Talk to Our Accountant",
  FILL_OUT_QUESTIONNAIRE : "Claim intake",
}

export const Interview_Process = ["CHAT_WITH_BOT","FILL_OUT_QUESTIONNAIRE"]
export const timeZones = [
  { name: "Pacific Standard Time", value: "Pacific Standard Time" },
  { name: "Eastern Standard Time", value: "Eastern Standard Time" },
  { name: "Central Standard Time", value: "Central Standard Time" },
  { name: "Mountain Standard Time", value: "Mountain Standard Time" },
];

export const statusBgColor: any = {
  Planned: "rgb(251 191 36 0.1)",
  "Ready To Start": "rgb(217 119 6 0.1)",
  "In Progress": "rgb(132 204 22 0.1)",
  Waiting: "rgb(21 94 117 0.1)",
  Completed: "rgb(20 83 45 0.1)",
  Cancelled: "rgb(136 19 55 0.1)",
};
export const TIME: any = [
  { time24: "09:00:00", time: "09:00 AM", isAvailable: false, label: "09:00 AM - 09:30 AM" },
  { time24: "09:30:00", time: "09:30 AM", isAvailable: false, label: "09:30 AM - 10:00 AM" },
  { time24: "10:00:00", time: "10:00 AM", isAvailable: false, label: "10:00 AM - 10:30 AM" },
  { time24: "10:30:00", time: "10:30 AM", isAvailable: false, label: "10:30 AM - 11:00 AM" },
  { time24: "11:00:00", time: "11:00 AM", isAvailable: false, label: "11:00 AM - 11:30 AM" },
  { time24: "11:30:00", time: "11:30 AM", isAvailable: false, label: "11:30 AM - 12:00 PM" },
  { time24: "12:00:00", time: "12:00 PM", isAvailable: false, label: "12:00 PM - 12:30 PM" },
  { time24: "12:30:00", time: "12:30 PM", isAvailable: false, label: "12:30 PM - 01:00 PM" },
  { time24: "13:00:00", time: "01:00 PM", isAvailable: false, label: "01:00 PM - 01:30 PM" },
  { time24: "13:30:00", time: "01:30 PM", isAvailable: false, label: "01:30 PM - 02:00 PM" },
  { time24: "14:00:00", time: "02:00 PM", isAvailable: false, label: "02:00 PM - 02:30 PM" },
  { time24: "14:30:00", time: "02:30 PM", isAvailable: false, label: "02:30 PM - 03:00 PM" },
  { time24: "15:00:00", time: "03:00 PM", isAvailable: false, label: "03:00 PM - 03:30 PM" },
  { time24: "15:30:00", time: "03:30 PM", isAvailable: false, label: "03:30 PM - 04:00 PM" },
  { time24: "16:00:00", time: "04:00 PM", isAvailable: false, label: "04:00 PM - 04:30 PM" },
  { time24: "16:30:00", time: "04:30 PM", isAvailable: false, label: "04:30 PM - 05:00 PM" },
  { time24: "17:00:00", time: "05:00 PM", isAvailable: false, label: "05:00 PM - 05:30 PM" },
  { time24: "17:30:00", time: "05:30 PM", isAvailable: false, label: "05:30 PM - 06:00 PM" },
  { time24: "18:00:00", time: "06:00 PM", isAvailable: false, label: "06:00 PM - 06:30 PM" },
  { time24: "18:30:00", time: "06:30 PM", isAvailable: false, label: "06:30 PM - 07:00 PM" },
  { time24: "19:00:00", time: "07:00 PM", isAvailable: false, label: "07:00 PM - 07:30 PM" },
  { time24: "19:30:00", time: "07:30 PM", isAvailable: false, label: "07:30 PM - 08:00 PM" },
  { time24: "20:00:00", time: "08:00 PM", isAvailable: false, label: "08:00 PM - 08:30 PM" },
  { time24: "20:30:00", time: "08:30 PM", isAvailable: false, label: "08:30 PM - 09:00 PM" },
  { time24: "21:00:00", time: "09:00 PM", isAvailable: false, label: "09:00 PM - 09:30 PM" },
  { time24: "21:30:00", time: "09:30 PM", isAvailable: false, label: "09:30 PM - 10:00 PM" },
  { time24: "22:00:00", time: "10:00 PM", isAvailable: false, label: "10:00 PM - 10:30 PM" },
  { time24: "22:30:00", time: "10:30 PM", isAvailable: false, label: "10:30 PM - 11:00 PM" },
  { time24: "23:00:00", time: "11:00 PM", isAvailable: false, label: "11:00 PM - 11:30 PM" },
  { time24: "23:30:00", time: "11:30 PM", isAvailable: false, label: "11:30 PM - 12:00 AM" },
  { time24: "00:00:00", time: "12:00 AM", isAvailable: false, label: "12:00 AM - 12:30 AM" },
  { time24: "00:30:00", time: "12:30 AM", isAvailable: false, label: "12:30 AM - 01:00 AM" },
  { time24: "01:00:00", time: "01:00 AM", isAvailable: false, label: "01:00 AM - 01:30 AM" },
  { time24: "01:30:00", time: "01:30 AM", isAvailable: false, label: "01:30 AM - 02:00 AM" },
  { time24: "02:00:00", time: "02:00 AM", isAvailable: false, label: "02:00 AM - 02:30 AM" },
  { time24: "02:30:00", time: "02:30 AM", isAvailable: false, label: "02:30 AM - 03:00 AM" },
  { time24: "03:00:00", time: "03:00 AM", isAvailable: false, label: "03:00 AM - 03:30 AM" },
  { time24: "03:30:00", time: "03:30 AM", isAvailable: false, label: "03:30 AM - 04:00 AM" },
  { time24: "04:00:00", time: "04:00 AM", isAvailable: false, label: "04:00 AM - 04:30 AM" },
  { time24: "04:30:00", time: "04:30 AM", isAvailable: false, label: "04:30 AM - 05:00 AM" },
  { time24: "05:00:00", time: "05:00 AM", isAvailable: false, label: "05:00 AM - 05:30 AM" },
  { time24: "05:30:00", time: "05:30 AM", isAvailable: false, label: "05:30 AM - 06:00 AM" },
  { time24: "06:00:00", time: "06:00 AM", isAvailable: false, label: "06:00 AM - 06:30 AM" },
  { time24: "06:30:00", time: "06:30 AM", isAvailable: false, label: "06:30 AM - 07:00 AM" },
  { time24: "07:00:00", time: "07:00 AM", isAvailable: false, label: "07:00 AM - 07:30 AM" },
  { time24: "07:30:00", time: "07:30 AM", isAvailable: false, label: "07:30 AM - 08:00 AM" },
  { time24: "08:00:00", time: "08:00 AM", isAvailable: false, label: "08:00 AM - 08:30 AM" },
  { time24: "08:30:00", time: "08:30 AM", isAvailable: false, label: "08:30 AM - 09:00 AM" },
  { time24: "09:00:00", time: "09:00 AM", isAvailable: false, label: "09:00 AM - 09:30 AM" }
];

export const TIME_SLOTS:Array<any>=TIME;

export const steppertabs: Array<progresBarTabsProps> = [
  {
    step: 1,
    name: "Business Information",
    percentage: 0,
    data: DemographicData,
  },
  { step: 2, name: "Loss Information", percentage: 20, data: LossInformation },
  { step: 3, name: "Coverage", percentage: 40, data: coverage },
  // {
  //   step: 4,
  //   name: "Financial statements",
  //   percentage: 60,
  //   data: FinancialStatement,
  // },
  { step: 5, name: "Repair Timeline", percentage: 80 },
  { step: 6, name: "Review", percentage: 100 },
];
export const statusBorderColor:any ={
  "Planned":"131313",
  "Ready To Start":"07780d",
  "Ready To Start - Send client requests":"07780d",
  "In Progress":"ffff006e",
  "In Progress - Kick-off / Setup":"ffff006e",
  "In Progress - Prep":"ffff006e",
  "In Progress - Process":"ffff006e",
  "In Progress - Review":"ffff006e",
  "In Progress - Follow-up":"ffff006e",
  "Waiting":"FF2C2C",
  "Waiting - Interview To Be Scheduled":"FF2C2C",
  "Waiting - Interview Scheduled":"FF2C2C",
  "Waiting - Interview Completed":"FF2C2C",
  "Waiting - Interview To Be Completed":"FF2C2C",
  "Waiting - Waiting for confirmation":"FF2C2C",
  // "Waiting - Interview Declined":"ddd",
  // "Waiting - Interview Accepted":"ddd",
  // "Waiting - Interview Tentatively Accepted":"FF2C2C",
  "Completed":"D97706",
  "Cancelled":"B60F0F",
  "Completed - Cancelled":"B60F0F"
}
// for Dashboard Claims
export const STATUS_OPTIONS = [
  { name: "Planned", value: "Planned" },
  { name: "Ready To Start", value: "Ready To Start" },
  {
    name: "In Progress - Kick-off / Setup",
    value: "In Progress - Kick-off / Setup",
  },
  {
    name: "In Progress - Prep",
    value: "In Progress - Prep",
  },
  {
    name: "In Progress - Process",
    value: "In Progress - Process",
  },
  {
    name: "In Progress - Review",
    value: "In Progress - Review",
  },
  {
    name: "In Progress - Follow-up",
    value: "In Progress - Follow-up",
  },
  {
    name: "Waiting - Waiting for info",
    value: "Waiting - Waiting for info",
  },
  {name:"Waiting - Interview To Be Scheduled",
   value:"Waiting - Interview To Be Scheduled"

  },
  {
    name: "Waiting - Interview Scheduled",
    value: "Waiting - Interview Scheduled",
  },
  {
    name: "Waiting - Interview Completed",
    value: "Waiting - Interview Completed",
  },
  {
    name: "Waiting - Interview To Be Completed",
    value: "Waiting - Interview To Be Completed",
  },
  // {
  //   name: "Waiting - Waiting for client",
  //   value: "Waiting - Waiting for client",
  // },
  {
    name: "Waiting - Waiting for confirmation",
    value: "Waiting - Waiting for confirmation",
  },
  {
    name: "Completed - Cancelled",
    value: "Completed - Cancelled",
  },
  // {
  //   name: "Waiting - Interview Declined",
  //   value: "Waiting - Interview Declined",
  // },
  // {
  //   name: "Waiting - Interview Accepted",
  //   value: "Waiting - Interview Accepted",
  // },
  // {
  //   name: "Waiting - Interview Tentatively Accepted",
  //   value: "Waiting - Interview Tentatively Accepted",
  // },
];

export const documentsTabs = [
  {
    label: "Upload Policy",
    value: "upload_policy",
    component: PolicyGrid,
  },
  {
    label: "Upload Financial Statements",
    value: "upload_financial_statements",
    component: FinancialStatementGrid,
  },
  {
    label: "Upload Pictures",
    value: "upload_pictures",
    component: PicturesGrid,
  },
  {
    label: "Upload Payroll Docs",
    value: "upload_payroll_docs",
    component: PayrollGrid,
  },
  {
    label: "Upload Invoices",
    value: "upload_invoices",
    component: InvoicesGrid,
  },
];

export const ASSIGNEE_LIST = [
  // { name: "Joseph Scarlato", value: "joseph.scarlato@ampcusforensics.com" },
  
  { name: "Grant Mizel", value: "grant.mizel@ampcusforensics.com" },
  {
    name: "Priya Krishnamurthy",
    value: "priya.krishnamurthy@ampcusforensics.com",
  },
  // { name: "Vedant Jadhav", value: "vedant.jadhav@ampcustech.com" },
  // { name: "Loknath Prajapati", value: "loknath.prajapati@ampcustech.com" },
];

export const OCR_FILE_TYPES = [
  {
    label: "1125-A",
    value: "1125-A",
  },
  {
    label: "1065",
    value: "1065",
  },
  {
    label: "1040-C",
    value: "1040-C",
  },
  {
    label: "1120",
    value: "1120",
  },
  {
    label: "1120-S",
    value: "1120-S",
  },
  {
    label: "Invoice",
    value: "Invoice",
  },
];

export const FORMAT_TYPE:any=[
  {label:"Jpg/Jpeg",value:"jpg"},
  {label:"Pdf",value:"pdf"}
]
export const LANGUAGE: any = [
  {
    value: "en",
    name: "English",
    flag: 'GB',
   
  },
  {
    value: "es",
    name: "Spanish",
    flag:'ES',

  },
  {
    value: "pt",
    name: "Portuguese",
    flag:'PT',

  },
];

  export const specialCharacterMap:any = {
    'at': '@',
    'at the rate': '@',
    'slash': '/',
    'minus': '-',
    'dot': '.',
    'hash': '#',
    'plus': '+',
    'underscore': '_',
    'equals': '=',
    'percent': '%',
    'ampersand': '&',
    'caret': '^',
    'dollar': '$',
    'exclamation': '!',
    'question': '?',
    'tilde': '~',
    "dash":'-',
    "hypen":"-",
    "hyphen":"-",
    'year around':'year-round',
    'year round':'year-round',
    'year-round.':'year-round',
    "Annual": "annual",
    "Monthly": "monthly",
  };