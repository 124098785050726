/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import { useLocation } from "react-router-dom";
import DropdownUser from "./DropdownUser";
import { IoReorderThreeOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
// import { Badge } from "@material-tailwind/react";
import { SidebarHeader } from "../Sidebar";
import { Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { logout } from "src/store/slices/user";
import { toast } from "react-toastify";
import { RootState } from "src/store";
import { useTranslation } from "react-i18next";
import { LANGUAGE } from "src/utils/constant";
import { setLanguageValue } from "src/store/slices/language";
import Select from "react-select";
import Flag from "react-world-flags";

interface userinfo {
  user: any;
}

const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
  hideSideBar?: boolean;
  hideHeader?: boolean;
}) => {
  const [title, setTitle] = useState("");
  const location = useLocation();

  useEffect(() => {
    setTitle(document.title?.split("|")?.[1]);
  }, [location, document.title]);

  return (
    !props?.hideHeader && (
      <header
        className={`sticky top-0 z-50 flex w-full order-b-2 shadow-lg border-gray-600 ${props?.hideSideBar ? "h-[8%]" : "h-[9%]"}`}
      >
        <div className="flex flex-grow items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11 h-[67px]">
          {props?.hideSideBar && (
            <div className="bg-white mb-2 -ml-6">
              <SidebarHeader />
            </div>
          )}
          {!props?.hideSideBar && <HeaderLeft title={title} {...props} />}
          <HeaderRight />
        </div>
      </header>
    )
  );
};

const HeaderLeft = (props: {
  title: string;
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
}) => {
  return (
    <div className="flex items-center gap-x-3">
      <HamburgerMenuAndLogo {...props} />

      <div>
        <span className="lg:text-title-sm text-title-xsm font-semibold text-primary dark:text-white">
          {props.title}
        </span>
      </div>
    </div>
  );
};

const HamburgerMenuAndLogo = (props: {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
}) => {
  return (
    <div className="flex items-center gap-2 sm:gap-4 lg:hidden">
      <button
        aria-controls="sidebar"
        onClick={(e) => {
          e.stopPropagation();
          props.setSidebarOpen(!props.sidebarOpen);
        }}
        className="z-50 block rounded-sm border border-stroke bg-white shadow-sm dark:border-strokedark dark:bg-boxdark lg:hidden"
      >
        <IoReorderThreeOutline className="w-8 h-8" />
      </button>
    </div>
  );
};

const HeaderRight = () => {
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state: RootState) => state.user);
  const location = useLocation();

  const _logout = () => {
    toast.done("You have logged out successfully.");
    localStorage.removeItem("UserInfo");
    dispatch(logout());
    window.location.href = window.location.origin;
  };
  const { i18n } = useTranslation();
  // const [userInfo, setUserInfo] = useState<userinfo>();
  const customOptionLabel = (option:any) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Flag code={option.flag} style={{ width: 20, height: 15, marginRight: 8 }} />
      {option.name}
    </div>
  );
  const onChangeLang = (selectedOption:any) => {
    const selectedLanguage = selectedOption.value;
 // Change the language dynamically
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
    dispatch(setLanguageValue(selectedLanguage));
  };
;
  useEffect(() => {
    if (localStorage.getItem("language")) {
      dispatch(setLanguageValue(localStorage.getItem("language")));
    }
  }, [0]);
  const { language } = useAppSelector((state: RootState) => state.language);
  const { t } = useTranslation();
 
  // useEffect(() => {
  //   let info = localStorage.getItem("UserInfo")
  //     ? localStorage.getItem("UserInfo")
  //     : null;
  //   setUserInfo({
  //     ...userInfo,
  //     user: info ? JSON.parse(JSON.parse(info)) : {},
  //   });
  // }, [userData])

  const hideLogout =
    location.pathname.includes("/schedule-interview/") &&
    location.search.includes("schedule");

  return (
    <div className="flex items-center gap-8 2xsm:gap-7">

      <Select
        placeholder="Select Language"
       className="p-0 pr-[18px] text-sm text-blue-gray-700 w-full h-[38px] rounded-md bg-white text-black-900 focus:shadow-lg focus:shadow-sky-100 focus:border-1 ring-4 ring-transparent placeholder:text-gray-500 placeholder:opacity-100 focus:border-sky-900 focus:border-t-sky-900 focus:ring-sky-900/10"
        id="language"
        value={{ value: language, name: LANGUAGE.find((option: any) => option.value === language)?.name,flag:LANGUAGE.find((option: any) => option.value === language)?.flag }}
        options={LANGUAGE}
        onChange={onChangeLang}
        formatOptionLabel={customOptionLabel}
        styles={{
          control: (provided) => ({
            ...provided,
            backgroundColor: "white", // Set the background color htmlFor the control
          }),
          option: (provided) => ({
            ...provided,
            color: "black",
            backgroundColor: "transparent", // Set the background color htmlFor the options
          }),
        }}
      />
      {!hideLogout && userData?.user?.id && (
        <Button onClick={_logout} className="!text-black !font-semibold whitespace-nowrap">
          {t('Logout')}
        </Button>
      )}
      <DropdownUser />
    </div>
  );
};

export default Header;
