/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Interview_Mode, Interview_Tabs } from "../../utils/constant";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import threedots from "../../assets/images/three_dots.gif";
import {
  clearSelection,
  updateDateTime,
  updateTimeZone,
  updateId,
  updateDate,
  updateTime,
  updateComments,
  updateSlotAvailable,
} from "../../store/slices/botSlice";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import TalkWithBotForm from "./TalkWithBotForm";
import SuccessPath from "src/Features/successPath";
import { getScheduleList, deleteSchedule } from "src/api/interview";
import { toast } from "react-toastify";
import { resetDeleteSchedulingValue } from "src/store/slices/interviewSlice";
import { Radio } from "@material-tailwind/react";
import robot from "../../assets/images/calleli.png";
import { useTranslation } from "react-i18next";
import { config } from "src/config";
import * as speechSDK from "microsoft-cognitiveservices-speech-sdk";
import { nextQuestion, startQuestions, submitAnswer } from "src/api/chatbotApi";
import { setMessagesValue, setUserInputMessage } from "src/store/slices/chatBotSlice";
import Microphone from "src/assets/images/Microphone.png";
import CallIcon from "src/assets/images/Call_icon.png";
import { saveTranscriptDocumets } from "src/api/documentApi";
import { Button } from "@material-tailwind/react";
import InlineLoader from "src/components/loader/InlineLoader";
import { mapSpecialCharacters } from "src/utils";
interface props {
  type: string;
  disableButton: boolean;
  siteData: any;
  setActiveTab: () => void;
  callEditScheduleApi: ({ item, data, moduleType }: InterViewProps) => void;
  onClickActionButton: ({
    data,
    actionType,
    moduleType,
  }: InterViewProps) => void;
  checkIsAttemptedRes?: any;
}

const TalkWithBot = ({
  type,
  disableButton,
  siteData,
  callEditScheduleApi,
  onClickActionButton,
  setActiveTab,
  checkIsAttemptedRes,
}: props) => {
  const formValue = useAppSelector((state: RootState) => state.talkBot);
  const { language } = useAppSelector((state: RootState) => state.language);
  const { t } = useTranslation();
  const {
    getScheduleListData,
    deleteScheduleError,
    deleteScheduleRes,
    editScheduleError,
    editScheduleRes,
  } = useAppSelector((state: RootState) => state.interview);
  const dispatch = useAppDispatch();
  const dispatchForScheduleList = useDispatch<AppDispatch>();
  const [multipleSelection, setMultipleSelection] = useState<Array<any>>([]);
  const [successMessage, setSuccesMessage] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [timeZones, setTimeZones] = useState([]);
  const [callType, setCallType] = useState(1);
  const [scheduleType, setScheduleType] = useState<number>(() => {
    if (type === "Call Eli") {
      return 1;
    } else if (type === "Talk to Our Accountant") {
      return 2;
    } else {
      return 0;
    }
  });
  
  const [userSpeak, setUserSpeak] = useState(false);
  const { chatbotMessage, loading } = useAppSelector(
    (state: RootState) => state.chatbot
  );
  const [latestQuestionAnswer, setLatestQuestionAnswer] = useState({
    question: "",
    anwser: "",
    error: false,
    options: [],
    is_multi_select: false,
  });
  const speechKey = config.AZURE_SPEECH_KEY;
  const region = config.AZURE_REGION;
  const [text, setText] = useState("");
  const [isSpeaking, setIsSpeaking] = useState(false);
  const speechlang: any = {
    en: {
      speechSynthesisLanguage: "en-US",
      speechSynthesisVoiceName: "en-US-JessaNeural",
    },
    es: {
      speechSynthesisLanguage: "es-ES",
      speechSynthesisVoiceName: "es-ES-LauraNeural",
    },
    pt: {
      speechSynthesisLanguage: "pt-BR",
      speechSynthesisVoiceName: "pt-BR-FranciscaNeural",
    },
  };
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  
  const handleDateTimeChange = (value: string) => {
    dispatch(updateDateTime(value));
  };

  const handleTimeZoneChange = (value: string) => {
    handleDateTimeChange("");
    dispatch(updateTimeZone(value));
  };

  const handleTimeChange = (value: string) => {
    dispatch(updateTime(value));
  };
  const handleDateChange = (value: string) => {
    dispatch(updateDate(value));
  };
  const handleComments = (value: string) => {
    dispatch(updateComments(value));
  };
  const handleSlot = (value: boolean) => {
    dispatch(updateSlotAvailable(value));
  };
  const handleItemIdChange = (data?: any, item?: any) => {
    setSelectedItem(item);
    dispatch(updateId(data));
  };

  const handleClearSelection = () => {
    dispatch(clearSelection());
    onClickActionButton({
      data: null,
      actionType: "RESET",
      moduleType: "TALK_WITH_BOAT",
    });
  };

  const callScheduleList = () => {
    const { email, claimNumber } = siteData;
    const payload = {
      insuredEmail: email,
      claimNumber: claimNumber,
      isAdmin: false,
    };
    dispatchForScheduleList(getScheduleList(payload));
  };

  useEffect(() => {
    fetch("/timezones.json")
      .then((response) => response.json())
      .then((data) => setTimeZones(data))
      .catch((error) => console.error("Error loading timezones:", error));
  }, []);

  useEffect(() => {
    if (siteData?.email && siteData?.claimId) {
      if (type !== Interview_Tabs.TALK_WITH_BOT) {
        callScheduleList();
      }
    }
  }, [siteData]);
  const handleSubmit = () => {
    if (formValue.id) {
      callEditScheduleApi({
        item: selectedItem,
        data: {
          // dateTime: formValue.dateTime,
          date: formValue.date,
          time: formValue.time,
          timeZone: formValue.timeZone,
          id: formValue.id,
        },
        moduleType:
          type === Interview_Tabs.ACCOUNTANT_CALL
            ? "TALK_WITH_ACCOUNTANT"
            : "TALK_WITH_BOAT",
      });
    } else {
      onClickActionButton({
        data: {
          date: formValue.date,
          time: formValue.time,
          timeZone: formValue.timeZone,
          message: formValue.comments,
        },
        actionType: "SUBMIT",
        moduleType:
          type === Interview_Tabs.ACCOUNTANT_CALL
            ? "TALK_WITH_ACCOUNTANT"
            : "TALK_WITH_BOAT",
      });
    }
    // setSuccesMessage(true);
  };
  const callDeleteSchedule = (id: any) => {
    const payload = {
      interviewId: id,
      claimsId: siteData?.claimNumber,
    };
    dispatchForScheduleList(deleteSchedule(payload));
  };
  const callResetValue = () => {
    dispatch(resetDeleteSchedulingValue());
  };

  useEffect(() => {
    if (deleteScheduleRes?.statusCode === 200) {
      toast.success(deleteScheduleRes?.message);
      callScheduleList();
      callResetValue();
    }
  }, [deleteScheduleRes]);
  useEffect(() => {
    if (editScheduleRes?.statusCode === 200) {
      toast.success(editScheduleRes?.message);
      callScheduleList();
      callResetValue();
      handleClearSelection();
    }
  }, [editScheduleRes]);
  useEffect(() => {
    if (deleteScheduleError) {
      toast.error(deleteScheduleRes?.message);
      callResetValue();
    }
    if (editScheduleError) {
      toast.error(editScheduleRes?.message);
      callResetValue();
    }
  }, [deleteScheduleError, editScheduleError]);
  useEffect(() => {
    if (chatbotMessage?.length > 0 && type === Interview_Tabs.TALK_WITH_BOT) {
      const latestMessage = chatbotMessage[chatbotMessage.length - 1]?.question;
      if (latestMessage) {
        startSpeaking(latestMessage);
      }
      if (chatbotMessage[chatbotMessage.length - 1]?.question) {
        setLatestQuestionAnswer({
          question: chatbotMessage[chatbotMessage.length - 1]?.question,
          anwser: "",
          error: false,
          options: chatbotMessage[chatbotMessage.length - 1]?.options,
          is_multi_select:
            chatbotMessage[chatbotMessage.length - 1]?.is_multi_select,
        });
      } else {
        setLatestQuestionAnswer({
          ...latestQuestionAnswer,
          anwser: chatbotMessage[chatbotMessage.length - 1]?.answer,
          error: false,
        });
      }
      if (chatbotMessage[chatbotMessage.length - 1]?.valid === false) {
        setLatestQuestionAnswer({
          ...latestQuestionAnswer,
          question: chatbotMessage[chatbotMessage.length - 1].question,
          anwser: chatbotMessage[chatbotMessage.length - 1].answer,
          error: true,
        });
      }
    }
    const filterData = chatbotMessage.filter(
      (item) => item.end_of_questions === true
    );
    if (filterData.length > 0) {
      setTimeout(() => {
        dispatch(setMessagesValue(null))
        localStorage.setItem("CHAT_BOT_DATA",JSON.stringify(""))
        _onSessionEnd(filterData[0]);
      }, 30000);
    }
   
  }, [JSON.stringify(chatbotMessage)]);
  const startSpeaking = (text: string) => {
    if (!text) {
      alert(t("Please enter text to speak"));
      return;
    }
    setUserSpeak(false)
    setIsSpeaking(true);

    // Create a Speech Config instance with the API key and region
    const speechConfig = speechSDK.SpeechConfig.fromSubscription(
      speechKey,
      region
    );

    // Optionally set the voice name and language
    speechConfig.speechSynthesisVoiceName =
      speechlang[language].speechSynthesisVoiceName; // Choose voice
    speechConfig.speechSynthesisLanguage =
      speechlang[language].speechSynthesisLanguage; // Set language

    // Create a synthesizer
    const synthesizer = new speechSDK.SpeechSynthesizer(speechConfig);

    synthesizer.speakTextAsync(
      text,
      (result) => {
        if (
          result.reason === speechSDK.ResultReason.SynthesizingAudioCompleted
        ) {
          console.log(t("Successfully synthesized the text to speech."));

          // Create an audio element to play the synthesized speech
          const audioBlob = new Blob([result.audioData]);
          const audioUrl = URL.createObjectURL(audioBlob);
          const audio = new Audio(audioUrl);
          audio.muted = true;
          audio.volume = 0;

          // Handle the event when the audio finishes playing
          audio.onended = () => {
            console.log(t("Audio playback finished."));
            setIsSpeaking(false); // Set speaking state to false after the speech ends
            // setTimeout(() => {
            //   if (latestQuestionAnswer?.error === false) {
            //     console.log(latestQuestionAnswer);
            //     speakNow();
            //   }
            // }, 1000);
            // setTimeout(() => {
            //   speakNow()
            // }, 300);
       
          };

          // Start playing the audio
          audio.play().catch((error) => {
            console.error("Error playing audio:", error);
            setIsSpeaking(false);
          });
        } else {
          console.error(t("Speech synthesis failed:"), result.errorDetails);
          setIsSpeaking(false);
        }
      },
      (error) => {
        console.error("Error occurred:", error);
        setIsSpeaking(false);
      }
    );
  };


  const initiateWebCall = () => {
    const oneDriveFolderName = `${siteData.buisnessName}-${siteData?.fileNumber}-${siteData?.claimNumber}`;
    dispatch(
      startQuestions({ user_id: oneDriveFolderName, language: language })
    ).then((res) => {
      callNextQuestion(oneDriveFolderName);
    });
    // startSpeaking()
  };
  const callNextQuestion = (folderName: string) => {
    dispatch(nextQuestion({ user_id: folderName, language: language }));
  };

  const speakNow = () => {
    setUserSpeak(true);
    const recognition = new (window.SpeechRecognition ||
    window.webkitSpeechRecognition)();
    recognition.lang = speechlang[language].speechSynthesisLanguage;
    recognition.start();
    recognition.onresult = (event) => {
      setUserSpeak(false);
      let input = event.results[0][0].transcript;
      input = mapSpecialCharacters(input);
      handleSendMessage(input);
    };
    recognition.onend = () => {
      console.log("Speech recognition has stopped.");
      setUserSpeak(false);
    };
    recognition.onerror = (event) => {
      setUserSpeak(false);
      console.error("Speech recognition error:", event.error);
      alert(t("Speech recognition failed. Please try again."));
    };
  };
  const updateMultiSelect = async (item: any) => {
    // Find the index of the item in the array
    let arr = [...multipleSelection];
    const index = arr.findIndex((element) => element === item);

    if (index !== -1) {
      // If the item is found, remove it
      arr.splice(index, 1);
    } else {
      // If the item is not found, add it to the array
      arr.push(item);
    }
    setMultipleSelection(arr);
  };
  const handleSendMessage = async (pInput?: string, e?: any) => {
    e?.preventDefault();
    const userInputMessage = pInput;
    if (!chatbotMessage) return;
    const lastQuestionIndex = chatbotMessage?.length - 1;
    const lastQuestion = chatbotMessage[lastQuestionIndex];
    const quesId = lastQuestion?.question_id;
    const option = lastQuestion?.options;
    if (!userInputMessage || !quesId) return;
    const userInput = {
      user_id: siteData.claimNumber,
      question_id: quesId,
      answer: userInputMessage,
    };
    dispatch(setUserInputMessage(userInput));
    const oneDriveFolderName = `${siteData.buisnessName}-${siteData?.fileNumber}-${siteData?.claimNumber}`;
    dispatch(
      submitAnswer({
        user_id: oneDriveFolderName,
        question_id: quesId,
        answer: userInputMessage,
        expected_responses: option,
        language: language,
      })
    );
    setText("");
  };
  const handleMultipleValue = async () => {
    const userInputMessage = multipleSelection;
    if (!chatbotMessage) return;
    const lastQuestionIndex = chatbotMessage?.length - 1;
    const lastQuestion = chatbotMessage[lastQuestionIndex];
    const quesId = lastQuestion?.question_id;
    const option = lastQuestion?.options;
    if (!userInputMessage || !quesId) return;
    const userInput = {
      user_id: siteData.claimNumber,
      question_id: quesId,
      answer: userInputMessage.join(","),
    };
    dispatch(setUserInputMessage(userInput));
    const oneDriveFolderName = `${siteData.buisnessName}-${siteData?.fileNumber}-${siteData?.claimNumber}`;
    dispatch(
      submitAnswer({
        user_id: oneDriveFolderName,
        question_id: quesId,
        answer: userInputMessage.join(","),
        expected_responses: option,
        language: language,
      })
    );
    setMultipleSelection([]);
  };

  const _onSessionEnd = async (lastMessageObj?: any) => {
    if (!siteData) return;
    const { authToken, claimId }: urlDecodedDataProps = siteData;
    let fileName = lastMessageObj.transcript_link?.split("/");
    if (fileName) {
      fileName = fileName[fileName.length - 1];
    }
    const payload: any = {
      authToken: authToken,
      claimId: claimId,
      downloadUrl: lastMessageObj.transcript_link, //claimNumber + "_session_attributes.xlsx",
      cloud: "AWS",
      type: "Transcript",
      filename: fileName,
      transcriptOneDriveFileId: lastMessageObj.transcript_file_id, //will get in the response of chatbot
    };
    // attemptInterViewOnClick();
    dispatch(saveTranscriptDocumets(payload));
    // dispatch(checkIsAttempted({ claimId: siteData.claimId }));
  };

  return !successMessage ? (
    <div className=" h-auto rounded-sm m-auto w-[100%] mt-4 py-2 flex justify-center bg-[#f1f1f1]">
      <div className="flex flex-col w-[100%] p-4">
        {/* <button disabled className="hover:cursor-default text-left"> */}
        {type === Interview_Tabs.TALK_WITH_BOT && (
          <>
            {/* <h2 className="text-[#009BDB] whitespace-nowrap font-poppins  text-base font-normal h-auto mb-2 mt-5">
              {t("When would you like to schedule the call?")}
            </h2> */}

            {/* <div className="flex gap-10">
              <Radio
                disabled={disableButton}
                name="schedule"
                label={t("Schedule a Call With Eli")}
                defaultChecked
                onClick={() => setScheduleType(1)}
              />
              <Radio
                disabled={disableButton}
                name="schedule"
                label={t("Speak with Eli Now")}
                onClick={() => setScheduleType(2)}
              />
            </div> */}
            {/* {scheduleType === 2 && (
              <div className="h-1 w-[550px] bg-[#D9D9D9] rounded-full mt-2"></div>
            )} */}
            {scheduleType === 1 && (
              <div className="flex">
                <div className="w-1/2">
                  <h2 className="text-[#009BDB] whitespace-nowrap font-poppins  text-base font-normal h-auto mb-2 mt-2">
                    {t("would you like to initiate the call?")}
                  </h2>
                  <div className="flex gap-10">
                    <Radio
                      name="callType"
                      label={t("Call Eli via Web Call")}
                      checked={callType === 1}
                      defaultChecked
                      onClick={() => setCallType(1)}
                    />
                    {/* <Radio
                      name="callType"
                      label={t("Call Eli via Mobile")}
                      checked={callType === 2}
                      onClick={() => setCallType(2)}
                    /> */}
                  </div>
                  {callType === 1 && (
                    <>
                      <p className="text-[#f50404] pl-4 text-sm font-semibold pt-3">
                        {t("Note:")}{" "}
                        <span className="!text-[#000]">
                          {t(
                            "Please use your microphone for better quality and calling experience."
                          )}
                        </span>
                      </p>
                      <div className="flex flex-row mt-6 gap-4 items-center pl-4 ">
                        {/* { && */}
                        <button
                          disabled={chatbotMessage?.length>0}
                          onClick={initiateWebCall}
                          className="w-full flex sm:w-auto flex-none bg-blue-600 hover:bg-blue-700 text-white text-sm leading-6 font-semibold py-3 px-4 border border-transparent rounded-xl focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200 disabled:cursor-pointer disabled:bg-grey-700"
                          style={{ borderRadius: "5px" }}
                        >
                          <img
                            src={CallIcon}
                            width={20}
                            height={25}
                            className="mr-2"
                          />
                          <span>{t("Initiate Call")}</span>
                        </button>
{/* } */}

                        {chatbotMessage?.length > 0 && (
                          <button
                            disabled={isSpeaking || loading}
                            className="w-full flex  sm:w-auto flex-none bg-black hover:bg-black text-white text-sm leading-6 font-semibold py-3 px-4 border border-transparent rounded-xl focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-900 focus:outline-none transition-colors duration-200 disabled:cursor-pointer disabled:bg-grey-700"
                            style={{ borderRadius: "5px" }}
                            onClick={speakNow}
                          >
                            {/* <a href="tel:+1 (888) 470-1067">Initiate Call</a> */}
                            <img
                              src={Microphone}
                              width={20}
                              height={25}
                              className="mr-2"
                            />
                            <span>{t("Click to Speak Your Response")}</span>
                          </button>
                        )}
                        {userSpeak && (
                          <img src={threedots} className="h-12 w-12" />
                        )}
                        {loading && <InlineLoader />}
                      </div>
                      <div className="flex flex-col mt-4">
                        {latestQuestionAnswer?.question && (
                          <div className="text-sm font-semibold ">
                            {t("Eli's Question")} :-{" "}
                            {latestQuestionAnswer?.question}
                          </div>
                        )}
                        <div className="flex">
                          {latestQuestionAnswer?.is_multi_select === false &&
                            latestQuestionAnswer?.options?.map(
                              (buttonMetaData: any, index: number) => (
                                <button
                                  key={index + "button"}
                                  value={buttonMetaData}
                                  disabled={isSpeaking || loading}
                                  className="capitalize disabled:cursor-not-allowed p-2 m-2 border-2 rounded-lg whitespace-nowrap border-gray-400 cursor-pointer"
                                  onClick={() => {
                                    handleSendMessage(buttonMetaData);
                                  }}
                                >
                                  {buttonMetaData}
                                </button>
                              )
                            )}
                        </div>
                        <div className=" grid grid-cols-4">
                          {latestQuestionAnswer?.is_multi_select === true && (
                            <>
                              {latestQuestionAnswer?.options?.map(
                                (buttonMetaData: any, index: number) => (
                                  <button
                                    key={index + "button"}
                                    value={buttonMetaData}
                                    disabled={isSpeaking || loading}
                                    className={`capitalize disabled:cursor-not-allowedp-2 m-2 p-2 border-2 rounded-lg whitespace-nowrap cursor-pointer ${multipleSelection.includes(buttonMetaData) ? "!border-blue-500 border-2" : "border-gray-400"}`}
                                    onClick={() => {
                                      updateMultiSelect(buttonMetaData);
                                    }}
                                  >
                                    {buttonMetaData}
                                  </button>
                                )
                              )}
                              {latestQuestionAnswer?.options?.length > 0 &&
                                latestQuestionAnswer?.is_multi_select ===
                                  true &&
                                multipleSelection?.length > 0 && (
                                  
                                  <div className="flex justify-end">
                                    <Button
                                      variant="text"
                                      color="blue"
                                      disabled={isSpeaking || loading}
                                      onClick={handleMultipleValue}
                                      className="mr-1 disabled:cursor-not-allowed border-2 border-blue-400"
                                    >
                                      <span>{t("Confirm")}</span>
                                    </Button>
                                  </div>
                                )}
                            </>
                          )}
                        </div>
                        {latestQuestionAnswer?.anwser && (
                          <div className="text-sm font-normal">
                            {t("Your Response")} :-{" "}
                            {latestQuestionAnswer?.anwser}
                          </div>
                        )}
                        {(latestQuestionAnswer?.options===undefined || latestQuestionAnswer?.options?.length === 0) && latestQuestionAnswer?.error && (
                          <div className="text-sm font-normal text-red-600">
                            {t(
                              "Error :- Your response is not correct, You can use below input to enter your response in appropriate format mention above..."
                            )}
                          </div>
                        )}
                      </div>
                      {(latestQuestionAnswer?.options===undefined || latestQuestionAnswer?.options?.length === 0) &&
                        latestQuestionAnswer?.error && (
                          <div className="flex flex-col mt-4">
                            <input
                              placeholder="Your Response"
                              className="border-black border-2 p-2"
                              value={text}
                              onChange={(e) => setText(e.target.value)}
                            />
                            <button
                              type="button"
                              disabled={isSpeaking || loading}
                              className="bg-black hover:bg-black text-white p-2 mt-2 w-[30%] rounded-sm"
                              onClick={(e) => handleSendMessage(text, e)}
                            >
                              {t("Submit")}
                            </button>
                          </div>
                        )}
                    </>
                  )}
                  {disableButton &&
                    checkIsAttemptedRes?.data?.attempt > 0 &&
                    type === Interview_Tabs.TALK_WITH_BOT && (
                      <p className="text-[#f50404] pl-4 text-sm font-semibold pt-3">
                        {t("Note")}:{" "}
                        <span className="">
                          {checkIsAttemptedRes?.message} with{" "}
                          {
                            Interview_Mode[
                              checkIsAttemptedRes?.data?.attemptedMediumType
                            ]
                          }
                        </span>
                      </p>
                    )}
                  {type === Interview_Tabs.TALK_WITH_BOT &&
                    scheduleType === 1 &&
                    callType === 2 && (
                      <h2 className="text-[#000]-500 font-poppins  text-base font-medium h-auto mt-6">
                        {t("To speak with Eli now please dial")}
                        <span className="text-[#1F7FBB] font-semibold">
                          {" "}
                          +1 (888) 470-1067
                        </span>
                        .
                      </h2>
                    )}
                </div>
                <div className="w-1/2">
                  <img
                    src={robot}
                    className="relative top-[-10%] left-[30%] h-96"
                  />
                </div>
              </div>
            )}
          </>
        )}
        {/* </button> */}

        {/* <div className="grid grid-cols-2">
          <div>ss</div>
          <img src={talkbotimg}  className="flex"/>
        </div> */}
        
        {/* {disableButton && checkIsAttemptedRes?.data?.attempt > 0 && (
          <p className="text-[#f50404] pl-5 font-semibold text-sm">
            {t("Note")}:{" "}
            <span className="">
              {checkIsAttemptedRes?.message} with{" "}
              {Interview_Mode[checkIsAttemptedRes?.data?.attemptedMediumType]}
            </span>
          </p>
        )} */}

        {scheduleType === 2 && (
          <>
            <TalkWithBotForm
              formValue={formValue}
              disableButton={disableButton}
              timeZones={timeZones}
              onDateTimeChange={handleDateTimeChange}
              onTimeZoneChange={handleTimeZoneChange}
              onTimeChange={handleTimeChange}
              onDateChange={handleDateChange}
              onSubmit={handleSubmit}
              onClear={handleClearSelection}
              onCommentChange={handleComments}
              onSlotAvailableChange={handleSlot}
              selectedDate={selectedDate}
              events={getScheduleListData}
              handleItemIdChange={handleItemIdChange}
              onDateChangee={setSelectedDate}
              callDeleteSchedule={callDeleteSchedule}
              setActiveTab={setActiveTab}
            />
          </>
        )}
      </div>
    </div>
  ) : (
    <div>
      {type === Interview_Tabs.ACCOUNTANT_CALL ? (
        <SuccessPath
          message={t(
            "Your offline scheduling request has been successfully submitted to the forensic accounting team. A confirmation email with further details will be sent to you shortly."
          )}
          setShow={setSuccesMessage}
        />
      ) : (
        <SuccessPath
          message={t("Thank you, Your call has been scheduled successfully!")}
          setShow={setSuccesMessage}
        />
      )}
    </div>
  );
};
const getFormattedDate = (date: Date) => {
  return date.toISOString().split("T")[0]; // Converts to YYYY-MM-DD format
};

export default TalkWithBot;
