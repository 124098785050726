

const DEV_BASE_URL={
    defaultUrl:"https://dev-claim-service-dverezamgbfbdqg3.eastus2-01.azurewebsites.net/api",
    chatBot:"https://testforensicchatbot.azurewebsites.net",
    questionarries: "https://dev-claim-service-dverezamgbfbdqg3.eastus2-01.azurewebsites.net/api"
}

const UAT_BASE_URL={
    defaultUrl:"https://uatapi.ampcusforensics.net/api",
    chatBot:"https://testforensicchatbot.azurewebsites.net",
    questionarries:"https://uatapi.ampcusforensics.net/api"
}
const PROD_BASE_URL={
    defaultUrl:"https://api.ampcusforensics.net/api",
    chatBot:"https://testforensicchatbot.azurewebsites.net",
    questionarries:"https://api.ampcusforensics.net/api"
}

const LOCAL_BASE_URL={
    defaultUrl:"http://localhost:8087/api",
    chatBot:"https://testforensicchatbot.azurewebsites.net",
    questionarries:"http://localhost:8087/api"
}

const LOCAL_DEV_URL={
    defaultUrl:"http://localhost:8080/api",
    chatBot:"https://testforensicchatbot.azurewebsites.net",
    // questionarries:"http://localhost:8080/api"
}


interface props{
    defaultUrl:string
    chatBot:string
    questionarries:string
}

// const BASE_URL:props=LOCAL_BASE_URL

// const BASE_URL:props=LOCAL_DEV_URL
const BASE_URL:props=DEV_BASE_URL
// const BASE_URL:props=UAT_BASE_URL
// const BASE_URL:props=PROD_BASE_URL

export default BASE_URL