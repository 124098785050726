/* eslint-disable import/first */
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "../app/app";
import "tailwindcss/tailwind.css";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@material-tailwind/react";
import { Auth0Provider } from "@auth0/auth0-react";
import { config } from "./config";
import { Provider } from "react-redux";
import { store } from "./store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import App from "./app/app";
// import TestApp from "./TestApp";
import { CookiesProvider } from "react-cookie";
import { Bounce, ToastContainer } from "react-toastify";
const queryClient = new QueryClient();
import './i18n';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Import your i18n configuration
ReactDOM.createRoot(document.getElementById("root")!).render(

  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <Auth0Provider
          domain={config.auth2BaseUrl}
          clientId={config.clientId}
          authorizationParams={{
            redirect_uri: window.location.origin + "/login/oauth2/code/okta",
          }}
        >
          <QueryClientProvider client={queryClient}>
            <CookiesProvider>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
          <I18nextProvider i18n={i18n}>
              <App />
              </I18nextProvider>
            </CookiesProvider>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          </QueryClientProvider>
        </Auth0Provider>
      </ThemeProvider>
  
    </Provider>
  </React.StrictMode>
);
