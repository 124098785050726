import * as cva from "class-variance-authority";
interface CustomButtonProps {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: any;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
}
const CustomButton: React.FC<CustomButtonProps> = ({
  label,
  onClick,
  disabled = false,
  style,
  className,
  icon
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={cva.cx(
        "relative border-box",
        "flex flex-row items-center gap-[8px]",
        "px-[12px] py-[12px]",
        "border-[1.2px] rounded-[4px]",
        "font-[Nunito-Bold] text-[14px] leading-[24px] lg:text-[14px] lg:leading-[14px]",
        "disabled:cursor-not-allowed",
        "focus:outline-none",
        "transition-all",
        "font-poppins",
        "!text-[#FFFFFF]",
        "disabled:text-[#616266] disabled:border-[#616266] disabled:cursor-not-allowed",
        "hover:!text-[#FFFFFF] hover:no-underline",
        className
      )}
      style={{
        ...style,
      }}
    >
      {icon && <span>{icon}</span>}
      {label}
    </button>
  );
};

export default CustomButton;
