import React from "react";

const DashboardLogo = ({ active }: { active: boolean }) => {
  return (
    <svg
      width="31"
      height="41"
      viewBox="0 0 31 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M0 0H31V41H0V0Z" fill="url(#pattern0_414_1503)" />
      <defs>
        <pattern
          id="pattern0_414_1503"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_414_1503"
            transform="matrix(0.01 0 0 0.00756098 0 0.121951)"
          />
        </pattern>
        <image
          style={{ filter: active ? "brightness(0) invert(1)" : "brightness(0) invert(1)" }}
          id="image0_414_1503"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqUlEQVR4nO3dvWoUURiH8ZHYKXZioXtO0FjZKFZbiYVYCsqCXoUYS3fOK7kAG8FSW7P4gY24578hVfACxCqV3oA2QVKEyGCsNPs1Z3cOu88PtlsY8j77TmaYsCkKAAAAAAAAAAAA/NdZ2z7tS7VXS91xIXZ4xU41i2om5x73T83tY+O7uuZCfO9C/OVNh7z0zwyq2VQz8ta/OsMUhye86akPOiCCxvsgBh24EK2aXfIczuIzQmiqM0I1u7QxSt0jhmqenuPdNDVs+6QP2iWI6gUJ2i06vZXaPXw5uEkMJbl4WS3jjfpBLG4QRImuJuNG7SAu6BVBlCSIM71MsCHqjThQr/ZBFoSfx6wIMj6CZIYgmSFIZgiSGYJkhiCZIcgEvGn9aGDHvdYTHIP7kJyGRZAJECQzng3JiydIXjxB8uIJkhdPkNlZs49nRv1hXvWeuQcJ2lzK5yGt7taVUU/nqvfM++dY2vuQFkEIMg42xDhlccoagl/qltmGcJUlgnCVtQQbMukxWrle9hJEBGFDjseGGBvChgzBhhgbwoYMwYYYGzKbDSnVHvawyZdq135ANeExpuEsvl6IG8NF4RflxnBReILkhSCZIUhmCJIZgixlkBEHcUGf+Ta5+OdeJsSdoUGCNmsHcaYXox4G8dJ4Mwh6nmJDHjFwJfnQtUJ8WDvIBfu0RhAlCeJMF2sHOdqSD0RR3RjvilTOd+NlZ/pJFE0XI8QfrSdbl4qUVst42wftEUWTBQnaa5WDW8UsuLJ/3Yf4lSgaN8aX6nuOi5nq9FZ8iA9c0Ftn+u6C9gmkv/dk+y7om7f4xtvg/lRfeskwldV9FEGs+QgEseYHTxBrftgEseYHTBBrfqgEseYHSRBb0CA8XIpZ/bummdzMAwAAAAAAAAAAFPn4Dc5Foau0gTTzAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
};

export default DashboardLogo;
