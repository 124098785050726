import React, { useEffect } from 'react'
import ColumnDefs from './ColumnDefs'
import { DataGrid } from '@mui/x-data-grid'
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from 'src/store';
import { getInterviewScheduleListByClaimNumber } from 'src/api/interview';
import { useAppSelector } from 'src/store/hooks';

export default function InterviewList() {
const{interviewScheduledList} = useAppSelector((state: RootState) => state.interview);
    const dispatch = useDispatch<AppDispatch>();
    useEffect(()=>{
        const payload={
            "insuredEmail":"loknath.prajapati@maidldrop.cc",
            "claimNumber":"claim-3-34--343434-34",
            "isAdmin":true
        }
        dispatch(getInterviewScheduleListByClaimNumber(payload))
    },[])
    console.log(interviewScheduledList)
  return (
    <div>
         <DataGrid
              rows={interviewScheduledList}
              columns={ColumnDefs({})}
            //   paginationModel={{
            //     page: currentPage,
            //     pageSize: rowsPerPage,
            //   }}
              pageSizeOptions={[10, 25, 50, 100]}
              rowCount={interviewScheduledList.length || 0}
              paginationMode="server"
              sortingMode="server"
            //   sortModel={sortModel}
              onSortModelChange={(model:any) => {
                // setSortModel(model);
                // if(sortModel.length>0){
                //   setSortModel(model)
                // }
                // else if(sortModel.length>0){
                //    const sortDataArray=[...sortModel]
                //    const sortData:any=sortDataArray[0]
                //    sortData.sort=sortData.sort==="desc"?"asc":"desc"
                //   setSortModel([sortData])
                // }else{
                //   setSortModel(model)
                // }

              
              }}
            //   onPaginationModelChange={handlePaginationChange}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#cfdce4",
                },
                "& .MuiDataGrid-root": {
                  overflowX: "auto",
                },
              }}
            //   checkboxSelection
            />
    </div>
  )
}
