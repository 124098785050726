/* eslint-disable jsx-a11y/alt-text */
import { GridColDef } from "@mui/x-data-grid";
import moment from "moment";

interface PropsData {
  handleClaimNumberClick?: (claim: any, action?: any) => void;
  handleIntiateReportingClick?: (claim: any) => void;
  isLoading?: boolean;
  activeClaimId?: any;
}

const ColumnDefs = ({
  handleClaimNumberClick,
}: PropsData): GridColDef<any>[] => [
//   {
//     field: "fileNumber",
//     headerName: "Ampcus File Number",
//     width: 170,
//     sortable: true,
//     renderCell: (params) => (
//       <div>
//         <span className="cursor-pointer">{params.value}</span>
//       </div>
//     ),
//   },
  {
    field: "claimsId",
    headerName: "Claim Number",
    width: 160,
    sortable: true,
    renderCell: (params) => (
      <div
        onClick={() =>
          handleClaimNumberClick && handleClaimNumberClick(params.row)
        }
      >
        <span className="text-[#0b8ce8] underline cursor-pointer">
          {params.value}
        </span>
      </div>
    ),
  },
  {
    field: "insuredBusinessName",
    headerName: "Insured Business Name",
    width: 150,
    sortable: true,
  },
  {
    field: "scheduleDate",
    headerName: "Schedule Date & Time",
    width: 180,
    sortable: true,
    renderCell: (params: any) => (
              <div>
                <span className="">
                  {moment(params.value).format("LL")} {moment(params.value).format("LT")}
                 
                </span>
              </div>
            ),
  },
 
  
  {
    field: "interviewType",
    headerName: "Interview Type",
    width: 180,
    sortable: true,
  },
  {
    field: "interviewStatus",
    headerName: "Interview Status",
    width: 180,
    sortable: true,
  },
  
 
  

  {
    field: "insuredEmail",
    headerName: "Insured Email",
    width: 180,
    sortable: true,
  },
  {
    field: "insuredName",
    headerName: "Insured Name",
    width: 180,
    sortable: true,
  },
  {
    field:"forensicAssignee",
    headerName: "Assignee Email",
    width: 180,
    sortable: true,
  },
  {
    field: "deleted",
    headerName: "Is Deleted",
    width: 180,
    sortable: true,
  },
  {
    field: "isInterviewScheduledOnline",
    headerName: "Online Mode",
    width: 150,
    sortable: true,
  },
  {
    field: "offlineScheduleMessage",
    headerName: "Offline Schedule Message",
    width: 150,
    sortable: true,
  },
];

export default ColumnDefs;
