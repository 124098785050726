import { DateTime } from 'luxon';
import { specialCharacterMap } from "./constant";
export const formatAMPM = (date: Date) => {
  let hours: any = date.getHours();
  let minutes: any = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours %= 12;
  hours = hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
};

export const getRandomColor = () => {
  return "#" + Math.random().toString(16).slice(2, 8);
};

export const allowValidMobileNumber=(value:any)=>{
const reg=/^[0-9\b]+$/
if(reg.test(value)||value===""){

}

}

export const emailValidation =(value:any)=>{
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/; 
  if(emailRegex.test(value)||value===""){
   return true
  }
  return false
}

export const onlyAlphabet=(value:any)=>{
  const alphabetRegex = /^[a-zA-Z]*$/;
  if(alphabetRegex.test(value)||value===""){
    return true
   }
   return false
}
export const chunkArray = (data:any, itemsPerRow:any) => {
  const rows = [];
  for (let i = 0; i < data.length; i += itemsPerRow) {
    rows.push(data.slice(i, i + itemsPerRow));
  }
  return rows;
};

export const convertToEST = (selectedDate: any, selectedTime: any = "9:00:00", selectedTimezone: any) => {
  if (!selectedDate || !selectedTime || !selectedTimezone) return null;

  // Create a string combining the selected date and time
  // const dateTimeStr = `${selectedDate.toISOString().split('T')[0]}T${selectedTime}`;
  const dateTimeStr = `${selectedDate}T${selectedTime}`;
  // Create a Luxon DateTime object in the selected time zone
  const localDateTime = DateTime.fromISO(dateTimeStr, { zone: selectedTimezone });

  // Convert the DateTime to EST (New York timezone)
  const estDateTime = localDateTime.setZone('America/New_York'); // EST zone

  // Check if the localDateTime is between 9 AM and 5 PM EST
  const hour = estDateTime.hour;
  const isInBusinessHours = hour >= 9 && hour < 17;  // 9:00 AM to 4:59 PM (5 PM is excluded)

  // You can return the result or take action based on availability
  return {
    formattedDateTime: estDateTime.toFormat('yyyy-MM-dd HH:mm:ss'),
    isInBusinessHours: isInBusinessHours
  };
};


export const convertESTToAnyTimezone = (estDateTimeStr: string, targetTimezone: string) => {
  if (!estDateTimeStr || !targetTimezone) return null;

  // Create a Luxon DateTime object in EST (America/New_York)
  const estDateTime = DateTime.fromISO(estDateTimeStr, { zone: 'America/New_York' });

  // Convert the EST DateTime to the target timezone
  const targetDateTime = estDateTime.setZone(targetTimezone);

  // Return the formatted DateTime and any other details you might need
  return targetDateTime.toFormat('yyyy-MM-dd HH:mm:ss')
};

 export function mapSpecialCharacters(input:any) {
    Object.keys(specialCharacterMap).forEach(word => {
      const regex = new RegExp(`\\b${word}\\b`, 'gi'); // Match whole words only
      input = input.replace(regex, specialCharacterMap[word]);
    });
    if (input.endsWith('.')) {
      input = input.slice(0, -1); // Remove the last character (dot)
    }
    return input;
  }
  