/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import "./newClaim.css";
import LeftSideSection from "./leftSideSection";
import RightSideSection from "./rightSideSection";
import { resetClaimData } from "src/store/slices/claimSlice";
import { useDispatch } from "react-redux";
const startNewClaim: React.FC = () => {

  return (
    <div className="bg-[#F0F0F0] p-2">
      {/* <LeftSideSection /> */}
      <RightSideSection />
    </div>
  );
};

export default startNewClaim;
