import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
interface columnState{
    selectedValues:any[]
}
const initialState: columnState = {
    selectedValues:["fileNumber",
    "claimNumber",
    "clientName",
    "insuredBusinessName",
    "status",
    "dateTimeCreated"]
}
const dashboardColumnSlice = createSlice({
    name: "dashboardColumn",
    initialState,
    reducers: {
        updateColumnVisibility: (state, action: PayloadAction<any>) => {
            state.selectedValues = action.payload;
        }

    }
});
export const dashboardColumn = (state: RootState) => state.dashboardColumn;

export const {updateColumnVisibility } = dashboardColumnSlice.actions;
export default dashboardColumnSlice.reducer;