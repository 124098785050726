import React from 'react'
import UserList from './UserList'

export default function ManageUser() {
  return (
    <div>
        <UserList />
    </div>
  )
}
